import { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
/* import NotFound from "pages/404"; */
import "./App.css";
import ScrollToTop from "./components/scroll-to-top";
import Home from "./pages/home";
import Services from "./pages/programmes";
import ProgrammesBursary from "./pages/programmes-bursary";
import ProgrammesUSP from "./pages/programmes-usp";
import ProgrammesGIDP from "./pages/programmes-gidp";
import ProgrammesLEAP from "./pages/programmes-leap";
import MediaArticles from "./pages/media-articles";
import MediaVideos from "./pages/media-videos";
import MediaInsights from "./pages/media-insights";
import MediaWorkReadiness from "./pages/media-work-readiness";
import MediaEvents from "./pages/media-events";
import Team from "./pages/team";
import About from "./pages/about";
import Foundation from "./pages/foundation";
import Programmes from "./pages/programmes";
import Platform from "./pages/platform";
import PlatformSingle from "./pages/platform-single";
import Newsletter from "./pages/newsletter";
import Jobs from "./pages/jobs";
import Contact from "./pages/contact";
import Page from "./pages/page";
import { OpenCloseContactContext } from "./contexts/contact";
import { WEB_ROUTES } from "./routes/web-routes";

function App() {
  const [openCloseContact, setOpenCloseContact] = useState(false);
  return (
    <OpenCloseContactContext.Provider
      value={{ openCloseContact, setOpenCloseContact }}
    >
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          {/* PUBLIC ROUTES */}
          <Route exact path={WEB_ROUTES.HOME} element={<Home />} />
          <Route path={WEB_ROUTES.ABOUT} element={<About />} />
          <Route path={WEB_ROUTES.FOUNDATION} element={<Foundation />} />
          <Route path={WEB_ROUTES.PROGRAMMES} element={<Programmes />} />
          <Route path={WEB_ROUTES.TEAM} element={<Team />} />
          <Route path={WEB_ROUTES.SERVICES} element={<Services />} />
          <Route
            path={WEB_ROUTES.PROGRAMMES_BURSARY}
            element={<ProgrammesBursary />}
          />
          <Route path={WEB_ROUTES.PROGRAMMES_USP} element={<ProgrammesUSP />} />
          <Route
            path={WEB_ROUTES.PROGRAMMES_GIDP}
            element={<ProgrammesGIDP />}
          />
          <Route
            path={WEB_ROUTES.PROGRAMMES_LEAP}
            element={<ProgrammesLEAP />}
          />
          <Route path={WEB_ROUTES.NEWSLETTER} element={<Newsletter />} />
          <Route path={WEB_ROUTES.MEDIA_ARTICLES} element={<MediaArticles />} />
          <Route path={WEB_ROUTES.MEDIA_VIDEOS} element={<MediaVideos />} />
          <Route path={WEB_ROUTES.MEDIA_INSIGHTS} element={<MediaInsights />} />
          <Route
            path={WEB_ROUTES.MEDIA_WORK_READINESS}
            element={<MediaWorkReadiness />}
          />
          <Route path={WEB_ROUTES.MEDIA_EVENTS} element={<MediaEvents />} />
          <Route path={WEB_ROUTES.JOBS} element={<Jobs />} />
          <Route path={WEB_ROUTES.CONTACT} element={<Contact />} />
          <Route path={WEB_ROUTES.PLATFORM} element={<Platform />} />
          <Route
            path={WEB_ROUTES.PLATFORM + `/:platform`}
            element={<PlatformSingle />}
          />
          <Route path={WEB_ROUTES.LEGAL + `/:page`} element={<Page />} />

          {/* 404 ROUTES */}

          {/* <Route path={PORTAL.NOT_FOUND} element={<NotFound />} />
            <Route path="*" element={<NotFound />} /> */}
        </Routes>
      </BrowserRouter>
    </OpenCloseContactContext.Provider>
  );
}

export default App;
