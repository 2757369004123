import { NavLink } from "react-router-dom";
import { WEB_ROUTES } from "../routes/web-routes";

export default function ProgrammeNavigation() {
  return (
    <>
      <div className="bg-[#091E35] md:rounded-tl-xl md:rounded-tr-xl md:flex w-full text-center text-white text-sm md:items-center divide-y md:divide-y-0 md:divide-x divide-solid">
        <NavLink
          to={WEB_ROUTES.PROGRAMMES_BURSARY}
          className="py-4 px-6 cursor-pointer hover:text-[#69C5D1] block"
        >
          BURSARY PROGRAMME
        </NavLink>
        <NavLink
          to={WEB_ROUTES.PROGRAMMES_USP}
          className="py-4 px-6 cursor-pointer hover:text-[#69C5D1] block"
        >
          UNIVERSITY SUPPORT PROGRAMME
        </NavLink>
        <NavLink
          to={WEB_ROUTES.PROGRAMMES_GIDP}
          className="py-4 px-6 cursor-pointer hover:text-[#69C5D1] block"
        >
          GRADUATE INTERNSHIP DEVELOPMENT PROGRAMME
        </NavLink>
        <NavLink
          to={WEB_ROUTES.PROGRAMMES_LEAP}
          className="py-4 px-6 cursor-pointer hover:text-[#69C5D1] block"
        >
          LEAP: WORK READINESS PROGRAMME
        </NavLink>
        <a
          href="https://rms.bluevinegroup.co.za/"
          target="_blank"
          className="bg-[#69C5D1] py-4 px-6 cursor-pointer md:rounded-tr-xl self-stretch content-center font-semibold block"
        >
          REGISTER
        </a>
      </div>
    </>
  );
}
