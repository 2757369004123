import { Fragment } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { useState } from "react";
import TeamItem from "../components/team-item";

export default function Team() {
  const team = [
    {
      name: "David Phetole Sekete",
      position: "Chairperson",
      image: "/images/team/david-phetole-sekete.webp",
      qualifications: "BSci (Unin) MBChB, MSc Med, MSc (University of London)",
      description:
        "<p class='mb-4'>David has 33 years of medical experience gained at both public and private hospitals.  He has extensive management experience in healthcare, property, and environmental health, and has been running a successful private practice for the last 30 years. He was responsible for the integration of the Ekurhuleni District clinics with the provincial clinics and hospitals from 1995-1997.</p><p class='mb-4'>In addition to these accomplishments, David is also a Director of MEDITECH (SA), a leading Health Information Company contracted to the South African Department of Health.  David owns Buhle Waste (Pty) Ltd, a waste management company, contracted to the South African Department of Health for the past 14 years.</p><p class='mb-4'>He is also the Director of ACSION Ltd, a property company listed on the Johannesburg Stock Exchange worth about 4 Billion Rand. He sits on the Investment Committee and Social and Ethics Committee of ACSION Ltd. He is also the Director of Aberrant Medical Supplies which provides medical point-of-care diagnostic products, including diagnosis, timely treatment, medicine dispensing, and compliance.</p><p class='mb-4'>He is the Director of Liseko with investments in Aspen Pharmaceuticals and SANLAM. He is also the Director of Ingocure (Pty) Ltd which sources quality products from global and local reputable companies.</p>",
    },
    {
      name: "Benjamin Mokgothu",
      position: "Acting CEO",
      image: "/images/team/benjamin-mokgothu.webp",
      qualifications:
        "Bachelor of Technology Construction Management at Tshwane University of Technology, Certificate in Project Management (University of Pretoria)",
      description:
        "<p class='mb-4'>Benjamin is a registered Professional Construction Project Manager and a member of the Clinical Engineering Association of South Africa (CEASA) with over 20 years of experience in Health Facilities Planning. After initially working as an Assistant Manager on the Soshanguve College building project, he joined the Department of Health North West Province as a Programme Manager responsible for the Clinic Building Programme, the Hospital Revitalisation Infrastructure Planning component as well as Maintenance of Health Infrastructure.</p><p class='mb-4'>Benjamin later joined the Department of Health Free State Province as Senior Manager of Infrastructure Management and his portfolio included the Hospital Revitalisation Programme, Clinic Building Programme, Health Technology, Maintenance Programme as well as Contract Management.</p><p class='mb-4'>As a Health Planner and Project Manager, he contributed to various projects including Moses Kotane Hospital, Vryburg Hospital, Mantsopa District Hospital, the Commissioning of Cecilia Makiwane Hospital, and Bloemfontein Forensic Mortuary among others.  He served as a member of the Hospital Revitalisation Peer Review Committee appointed by the Director General of the National Department of Health.</p><p class='mb-4'>Benjamin served Sakhiwo in different portfolios, as Director of Operations, and Chief Operations Officer. He was also the Project Director for the Commissioning of Cecilia Makiwane Hospital, Sakhiwo Facility Maintenance Consortium Projects and Sipetu Hospital.  He is currently the Acting Chief Executive Officer of the company.</p>",
    },
    {
      name: "Quinton Zunga",
      position: "Non-Executive Director",
      image: "/images/team/quinton-zunga.webp",
      qualifications: "B.Bus.Sci, MSc (University of London)",
      description:
        "<p class='mb-4'>Quinton is the founder and current chief executive officer of RH Bophelo. Before this, he was co-founder and executive director of Arkein Capital Partners. Quinton has sixteen years of high-level business experience, including working twelve years at senior levels in investment banking and four years in private equities.</p><p class='mb-4'>Before founding RH Managers and co-founding Arkein Group, Quinton was a Director at Bank of America Merrill Lynch SA (BofAML) and head of its debt capital markets for South Africa and sub-Saharan Africa from 2007 to 2011. In that role, Quinton was instrumental in developing BofAML's Sub-Saharan Africa business focussing on regional hubs South Africa, Nigeria, and Kenya. He was instrumental in raising over US$1.5 billion in capital in these regions, and he built deep relationships.</p><p class='mb-4'>Before joining BofAML, Quinton was Head of Debt Capital Markets at Absa Capital – a role he assumed after the merger of Barclays and Absa Bank. Before this merger, Quinton was head of Debt Capital Markets at Barclays and was responsible for a team that covered eleven African countries where Barclays had banking operations. He helped develop Africa's Debt Capital Markets in these countries, launching the first medium-term notes in Botswana, Tanzania, Mauritius, and Zambia. He also led the issue of municipal bonds by the City of Johannesburg. Quinton raised over US$ 2.0 billion in forty transactions, and he was the lead manager or co-lead manager over his seven years at Barclays.</p>",
    },
    {
      name: "Matsela Bantsi",
      position: "Non-Executive Director",
      image: "https://placehold.co/1000x1200",
      qualifications:
        "Board member and Chairperson of Remuneration Committee/Remco, Bachelor of Arts Social Sciences degree and a postgraduate Higher Education Diploma (University of Limpopo)",
      description:
        "<p class='mb-4'>Matsela is a general human resource professional with over 20 years of experience in various industries within South Africa and across twelve other African countries and India. Her formal career started at the now Independent Police Investigative Directorate (IPID) and progressed through Molope/Reserve Group, Standard Bank, Momentum, Sanlam Group (Emerging Markets) and lastly AFGRI Poultry.</p><p class='mb-4'>She completed an Advanced Programme in Human Resources Management from the University of South Africa (Unisa)and further completed a Management Development Programme (MDP) from the Unisa School of Business Leadership and an International Executive Development Programme with the University of Witwatersrand (Wits) Business School in partnership with the London School of Business. She is a recipient of a scholarship for the Strategic Leadership Programme with the International Women's Forum South Africa (IWFSA) in partnership with the Gordon Institute of Business Sciences (GIBS).</p><p class='mb-4'>Matsela is a member of the Institute of Directors in South Africa (IoDSA) and serves as an independent non-executive Chairperson of the board of Health-WorX Medical Centres, a board member and an Investment Committee member for Razorite Healthcare Managers, an Investment Committee Member of RQ Capital Partners Fund and a non-executive Director for the City of Johannesburg Group Smart City Advisory board.</p><p class='mb-4'>Furthermore, she is a Council member and Deputy Chairperson of the Council for the University of Limpopo. She is also the Chairperson of the Human Resources Committee and a member of the Remuneration Committee, the Finance Committee, and the Executive Committee of the Council.</p><p class='mb-4'>She has served as an alternate director and invitee member on several boards in both South Africa and other African countries.</p>",
    },
    {
      name: "Braam van der Hoven",
      position: "Non-Executive Director",
      image: "/images/team/braam-van-der-hoven.webp",
      qualifications: "B.Arch (University of the Free State); Arch. (SA)",
      description:
        "<p class='mb-4'>Braam's portfolio includes being a director at Bartsch as well as the Hospital Design Group. His main areas of expertise are healthcare projects and government institutions. He has extensive experience in architectural design, project co-ordination and health care facilities' design as well as business development and marketing.</p><p class='mb-4'>As architect and project leader, he has contributed to various projects such as the Nelson Mandela Academic Hospital (R260 million) and the document drawn up by the Free State Government, the 'Standard Clinic Building Program'. His experience also includes international projects, such as the Good Hope Diagnostic Treatment Centre in London, UK.</p>",
    },
    {
      name: "Piet van Heerden",
      position: "Non-Executive Director",
      image: "/images/team/piet-van-heerden.webp",
      qualifications: "B.Arch (University of the Free State); Arch (SA)",
      description:
        "<p class='mb-4'>Piet's portfolio includes being a Director and Architect at Bartsch Architects and a Director at Hospital Design Group. He has extensive experience in design, project management, marketing, business development, overall project coordination, and acting as liaison.</p><p class='mb-4'>His architectural and infrastructure development experience covers education, health, and government facilities. He has been instrumental in the design of the Nelson Mandela Academic Hospital in Umtata, the Kimberley Mental Health Institute, and the award-winning Vryburg Hospital.</p><p class='mb-4'>He has also extended Hospital Design Group's operations to Africa and internationally with projects such as the Edgeware Hospital in London (UK) and various projects in Angola, Ethiopia, and Namibia.</p><p class='mb-4'>His experience includes health brief development, accommodation schedules, master plans, and detailed design of health facilities. Piet has vast experience in development control plans, site rationalisation, and space utilisation studies.</p>",
    },
    {
      name: "Johann Loubser",
      position: "Alternate Non-Executive Director",
      image: "/images/team/johann-loubser.webp",
      qualifications:
        "B.A., LL.B, Hons. B (B&A) at the University of Stellenbosch, Qualified Attorney and member of the Side-Bar",
      description:
        "<p class='mb-4'>Johann's portfolio includes managing the Department of Health's Project Management Unit in Limpopo. He practised commercial and contract law for over 10 years and has a practical background in protracted construction arbitrations including the Transhex railway tunnel and major housing development Projects in the Eastern Cape. He was previously part of the management team of Picardi Investments.</p><p class='mb-4'>Johann is an expert in Public Private Partnerships (PPP) in both local and international contexts. In addition to his knowledge of the contract law and administration niche, he has extensive experience in property development strategies and administration as the owner and executive director of Stercorp Properties. Stercorp Group is a property development company specialising in retail development, corporate finance, and all facets of property development and structured project finance.</p><p class='mb-4'>Johann has extensive experience in financial management, legal compliance and contract administration, health infrastructure project development and management, business development and marketing, structured finance and PPPs.</p>",
    },
    {
      name: "Andrew Masekesa",
      position: "Alternate Non-Executive Director",
      image: "/images/team/andrew-masekesa.webp",
      qualifications: "BCom Hons. Finance & Investments (UNISA)",
      description:
        "<p class='mb-4'>Andrew is currently an Investment Principal at RH Managers, where he brings his extensive experience in debt and equity transactions to manage new infrastructure projects. He is responsible for overseeing the entire project cycle, from origination to structuring, debt raising, monitoring, and workout.</p><p class='mb-4'>Andrew joined RH in 2015 as an Investment Analyst. Before this, he began his career as an Accountant at RSG Engineering where he worked on several government infrastructure development projects. In 2013 he moved to NMG Administrators and Actuaries as a Fund Accountant, where he gained considerable professional experience in the retirement and employee benefit funds sector encompassing retail and financial services.</p>",
    },
  ];

  return (
    <>
      <Header />
      <main>
        <section className="bg-white py-16">
          <div className="container mx-auto md:px-0 px-8">
            <div className="text-base leading-7 text-gray-700">
              <h1 className="pb-5 text-5xl font-semibold">
                Our leadership team
              </h1>
              <div className="w-20 border-b-4 border-red-600 mb-5"></div>
            </div>
          </div>
        </section>

        <section className="bg-white pb-16">
          <div className="container mx-auto md:px-0 px-8">
            <ul className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
              {team.map((member, i) => (
                <Fragment key={i}>
                  <TeamItem member={member} />
                </Fragment>
              ))}
            </ul>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
}
