import { NavLink } from "react-router-dom";
import { WEB_ROUTES } from "../routes/web-routes";

export default function MediaArticleItem({ article }) {
  return (
    <>
      <div className="flex flex-col items-start p-5 bg-[#FFFAF7] border-2 rounded-xl border-neutral-100">
        <div className="relative w-full">
          <NavLink to={`#.`}>
            <img
              src={article.image}
              alt={article.title}
              className="aspect-[16/9] w-full bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2] rounded-xl"
            />
          </NavLink>

          <div className="absolute -bottom-5 left-0 bg-[#091E35] p-2 text-xs font-semibold rounded-lg ">
            <span className="text-white">
              <span
                dangerouslySetInnerHTML={{
                  __html: article.date,
                }}
              />
            </span>
          </div>
        </div>
        <div className="max-w-xl">
          <div className="relative">
            <h3 className="mt-10 text-2xl text-gray-900 font-semibold">
              <NavLink className="hover:underline" to={`#.`}>
                {article.title}
              </NavLink>
            </h3>
            <p className=" mt-3 mb-5">{article.snippet}</p>
            <span className="bg-[#FFFAF7] text-[#B09455] border-2 border-[#B09455] py-2 px-5 rounded-full float-start cursor-pointer font-semibold">
              Read more
              <i className="fi fi-rr-arrow-right text-xl float-right mt-0.5 ml-1.5"></i>
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
