import { Fragment, useRef, useCallback } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { NavLink } from "react-router-dom";
import { WEB_ROUTES } from "../routes/web-routes";
import ProgrammeNavigation from "../components/programme-navigation";
import MediaArticleItem from "../components/media-article-item";
import FormNewsletter from "../components/form-newsletter";
// import Swiper core and required modules
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
// import Swiper bundle with all modules installed
import { Swiper, SwiperSlide } from "swiper/react";
// import styles bundle
import "swiper/css/bundle";

export default function Home() {
  const news = [
    {
      title: "PPS LEAP Work-Readiness Programme: your path to success",
      snippet:
        "More than 1.5 million individuals with post-school qualifications in South Africa are unemployed. Despite their potential, these talented individuals often lack the essential skills and readiness employers require.",
      image: "/images/insights/PC-1.png",
      date: "12 Oct<br/>2023",
      description: "<p class='mb-4'></p>",
    },
    {
      title: "What I wish I knew before starting my internship",
      snippet: "Accepting an internship is an exciting moment.",
      image: "/images/articles/saad-chaudhry-YNM4KStg78I-unsplash.jpg",
      date: "27 Sep<br/>2022",
      description: "<p class='mb-4'></p>",
    },

    {
      title: "Integrating work with life",
      snippet:
        "Joining the workplace, I did not know what to expect. Here is some advice I wish I had but unfortunately had to learn the hard way.",
      image: "/images/articles/pickled-stardust-4SxpE-CtDUo-unsplash_0.jpg",
      date: "27 Sep<br/>2022",
      description: "<p class='mb-4'></p>",
    },
  ];

  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  return (
    <>
      <Header />
      <main>
        <section className="bg-white py-10">
          <div className="container mx-auto">
            <div className="mx-auto md:flex items-center">
              <div className="max-w-xl">
                <div className="text-base leading-7 text-gray-700 md:px-0 px-8">
                  <h1 className="pb-5 text-5xl md:text-6xl font-semibold">
                    LEAP: WORK READINESS PROGRAMME
                  </h1>
                  <div className="max-w-xl">
                    <p className="pb-6 text-xl">
                      Connecting professionals, graduates and companies with the
                      best.
                    </p>
                    <NavLink
                      to={WEB_ROUTES.PROGRAMMES}
                      className="bg-[#B09455] text-white py-2 px-5 rounded-full md:mb-0 mb-4 float-start cursor-pointer font-semibold"
                    >
                      Learn more
                      <i className="fi fi-rr-arrow-right text-xl float-right mt-0.5 ml-1.5"></i>
                    </NavLink>
                  </div>
                </div>
              </div>

              <div className="w-full mt-10 md:mt-0">
                <div className="relative md:px-0 px-8">
                  <img
                    className="h-full w-full object-cover"
                    src="/images/main-banner.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container mx-auto">
            <div className="max-w-5xl">
              <ProgrammeNavigation />
            </div>
          </div>
        </section>

        <section className="relative py-16 text-[#091E35] bg-[#F4ECE5] overflow-visible px-8 md:px-0">
          <img
            className="-mt-16 w-full hidden md:visible"
            src="/images/watermark.png"
          />

          <div className="container mx-auto mb-5">
            <h2 className="text-5xl">
              <span className="font-light">Welcome to </span>
              <span className="font-bold">Professionals Connect</span>
            </h2>
          </div>

          <div className="container mx-auto mb-8">
            <p>
              The Professionals Connect platform was developed with students,
              graduates, and young professionals in mind. Our portal provides
              users with relevant industry-specific information, job
              opportunities, and mentorship programs. We collaborate with
              professional associations, corporations, academic institutions,
              and experienced professionals to offer opportunities and
              information aimed at empowering the youth.
            </p>
          </div>

          <div className="container mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-y-5 md:gap-y-0 gap-x-0 md:gap-x-10 ">
              <div className="rounded-lg bg-white p-5 shadow-md">
                <h3 className="text-[#A76952] float-start font-bold text-2xl cursor-pointer hover:underline">
                  <NavLink to={WEB_ROUTES.JOBS}>Find a job</NavLink>
                </h3>
                <NavLink
                  to={WEB_ROUTES.JOBS}
                  className="bg-[#A76952] text-white float-end px-4 py-2 text-xs rounded-full font-bold cursor-pointer"
                >
                  SEARCH
                </NavLink>
                <div className="clear-both"></div>
                <p className="mt-3">
                  Professionals Connect is a user-friendly and innovative
                  platform where users can search for relevant job
                  opportunities.
                </p>
              </div>

              <div className="rounded-lg bg-white p-5 shadow-md">
                <h3 className="text-[#82967F] float-start font-bold text-2xl cursor-pointer hover:underline">
                  <a
                    href="https://rms.bluevinegroup.co.za/register/Recruiter"
                    target="_blank"
                  >
                    Post a job
                  </a>
                </h3>
                <a
                  href="https://rms.bluevinegroup.co.za/register/Recruiter"
                  target="_blank"
                  className="bg-[#82967F] text-white float-end px-4 py-2 text-xs rounded-full font-bold cursor-pointer"
                >
                  REGISTER
                </a>
                <div className="clear-both"></div>
                <p className="mt-3">
                  Utilise our Recruitment Management System to post job
                  opportunities and find the right candidate for your
                  organization.
                </p>
              </div>

              <div className="rounded-lg bg-white p-5 text-center shadow-md">
                <a
                  href="https://www.pps.co.za/2022/foundation-annual-report"
                  target="_blank"
                  className=" text-[#70758C] font-bold text-2xl cursor-pointer hover:underline"
                >
                  The 2023 PPS Foundation Annual Report
                </a>
                <div className="w-full clear-both mt-4"></div>
                <a
                  href="https://www.pps.co.za/2022/foundation-annual-report"
                  target="_blank"
                  className="bg-[#70758C] text-white px-4 py-2 text-xs rounded-full font-bold cursor-pointer"
                >
                  READ MORE
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="relative py-16 text-[#091E35] px-8 md:px-0">
          <div className="container mx-auto mb-10">
            <h2 className="text-5xl md:float-start mb-10 md:mb-0">
              <span className="font-bold">Engage </span>
              <span className="font-light">with our platform</span>
            </h2>
            <a
              href="https://rms.bluevinegroup.co.za/register/Candidate"
              target="_blank"
              className="bg-[#69C5D1] text-white py-3 px-5 rounded-full float-start md:float-end md:mb-0 mb-4 cursor-pointer font-semibold"
            >
              Register on our platform
              <i className="fi fi-rr-arrow-right text-xl float-right mt-0.5 ml-1.5"></i>
            </a>
            <div className="clear-both"></div>
          </div>

          <div className="container mx-auto mb-5">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-x-5 gap-y-10">
              <div className="flex items-start">
                <NavLink
                  to={WEB_ROUTES.PLATFORM_MENTORSHIP}
                  className="float-left"
                >
                  <div className="font-semibold text-white text-5xl rounded-full bg-[#70758C] py-5 px-5 mr-5 cursor-pointer">
                    <div className="w-20 h-20 flex items-center justify-center">
                      <i className="fi fi-rr-referral-user"></i>
                    </div>
                  </div>
                </NavLink>
                <div>
                  <h3 className="text-2xl font-semibold cursor-pointer">
                    <NavLink
                      to={WEB_ROUTES.PLATFORM_MENTORSHIP}
                      className="hover:underline"
                    >
                      Mentorship
                    </NavLink>
                  </h3>

                  <ul>
                    <li>Mentorship by experts</li>
                    <li>Mentorship matched with mentees</li>
                  </ul>
                  <NavLink
                    to={WEB_ROUTES.PLATFORM_MENTORSHIP}
                    className="inline-block text-[#69C5D1]"
                  >
                    Learn more
                    <i className="fi fi-rr-arrow-right text-xl float-right mt-0.5 ml-1.5"></i>
                  </NavLink>
                </div>
              </div>

              <div className="flex items-start">
                <NavLink
                  to={WEB_ROUTES.PLATFORM_WORK_READINESS}
                  className="float-left"
                >
                  <div className="font-semibold text-white text-5xl rounded-full bg-[#BF785E] py-5 px-5 mr-5 cursor-pointer">
                    <div className="w-20 h-20 flex items-center justify-center">
                      <i className="fi fi-rr-skill-user"></i>
                    </div>
                  </div>
                </NavLink>
                <div>
                  <h3 className="text-2xl font-semibold cursor-pointer">
                    <NavLink
                      to={WEB_ROUTES.PLATFORM_WORK_READINESS}
                      className="hover:underline"
                    >
                      Work Readiness
                    </NavLink>
                  </h3>

                  <ul>
                    <li>Interview Preparation Skills</li>
                    <li>Virtual Access to Materials</li>
                    <li>Career Guidance & Workspace Etiquette</li>
                  </ul>
                  <NavLink
                    to={WEB_ROUTES.PLATFORM_WORK_READINESS}
                    className="inline-block text-[#69C5D1]"
                  >
                    Learn more
                    <i className="fi fi-rr-arrow-right text-xl float-right mt-0.5 ml-1.5"></i>
                  </NavLink>
                </div>
              </div>

              <div className="flex items-start">
                <NavLink
                  to={WEB_ROUTES.PLATFORM_FINANCIAL}
                  className="float-left"
                >
                  <div className="font-semibold text-white text-5xl rounded-full bg-[#81957E] py-5 px-5 mr-5 cursor-pointer">
                    <div className="w-20 h-20 flex items-center justify-center">
                      <i className="fi fi-rr-chart-histogram"></i>
                    </div>
                  </div>
                </NavLink>

                <div>
                  <h3 className="text-2xl font-semibold cursor-pointer">
                    <NavLink
                      to={WEB_ROUTES.PLATFORM_FINANCIAL}
                      className="hover:underline"
                    >
                      Financial Literacy
                    </NavLink>
                  </h3>

                  <ul>
                    <li>Budgeting</li>
                    <li>Financial Education</li>
                    <li>Financial Planing</li>
                  </ul>

                  <NavLink
                    to={WEB_ROUTES.PLATFORM_FINANCIAL}
                    className="inline-block text-[#69C5D1]"
                  >
                    Learn more
                    <i className="fi fi-rr-arrow-right text-xl float-right mt-0.5 ml-1.5"></i>
                  </NavLink>
                </div>
              </div>

              <div className="flex items-start">
                <NavLink
                  to={WEB_ROUTES.PLATFORM_JOB_OPPORTUNITIES}
                  className="float-left"
                >
                  <div className="font-semibold text-white text-5xl rounded-full bg-[#B09455] py-5 px-5 mr-5 cursor-pointer">
                    <div className="w-20 h-20 flex items-center justify-center">
                      <i className="fi fi-rr-briefcase"></i>
                    </div>
                  </div>
                </NavLink>

                <div>
                  <h3 className="text-2xl font-semibold cursor-pointer">
                    <NavLink
                      to={WEB_ROUTES.PLATFORM_JOB_OPPORTUNITIES}
                      className="hover:underline"
                    >
                      Job Opportunities
                    </NavLink>
                  </h3>

                  <ul>
                    <li>Job Averts</li>
                    <li>Same Site Applications</li>
                    <li>Filtered Job Search</li>
                  </ul>

                  <NavLink
                    to={WEB_ROUTES.PLATFORM_JOB_OPPORTUNITIES}
                    className="inline-block text-[#69C5D1]"
                  >
                    Learn more
                    <i className="fi fi-rr-arrow-right text-xl float-right mt-0.5 ml-1.5"></i>
                  </NavLink>
                </div>
              </div>

              <div className="flex items-start">
                <NavLink
                  to={WEB_ROUTES.PLATFORM_CV_BUILDER}
                  className="float-left"
                >
                  <div className="font-semibold text-white text-5xl rounded-full bg-[#5D465D] py-5 px-5 mr-5 cursor-pointer">
                    <div className="w-20 h-20 flex items-center justify-center">
                      <i className="fi fi-rr-resume"></i>
                    </div>
                  </div>
                </NavLink>

                <div>
                  <h3 className="text-2xl font-semibold cursor-pointer">
                    <NavLink
                      to={WEB_ROUTES.PLATFORM_CV_BUILDER}
                      className="hover:underline"
                    >
                      CV Builder
                    </NavLink>
                  </h3>

                  <ul>
                    <li>Integrated for Professionals</li>
                    <li>Connect Automatic CV Generator</li>
                    <li>Multiple Templates / Video Options</li>
                  </ul>
                  <NavLink
                    to={WEB_ROUTES.PLATFORM_CV_BUILDER}
                    className="inline-block text-[#69C5D1]"
                  >
                    Learn more
                    <i className="fi fi-rr-arrow-right text-xl float-right mt-0.5 ml-1.5"></i>
                  </NavLink>
                </div>
              </div>

              <div className="flex items-start">
                <NavLink
                  to={WEB_ROUTES.PLATFORM_INSIGHTS}
                  className="float-left"
                >
                  <div className="font-semibold text-white text-5xl rounded-full bg-[#A25C5C] py-5 px-5 mr-5 cursor-pointer">
                    <div className="w-20 h-20 flex items-center justify-center">
                      <i className="fi fi-rr-web-design"></i>
                    </div>
                  </div>
                </NavLink>
                <div>
                  <h3 className="text-2xl font-semibold cursor-pointer">
                    <NavLink
                      to={WEB_ROUTES.PLATFORM_INSIGHTS}
                      className="hover:underline"
                    >
                      Blog Insights
                    </NavLink>
                  </h3>

                  <ul>
                    <li>Blog Articles</li>
                    <li>Industry Insights</li>
                    <li>Notifications (based on study fields)</li>
                  </ul>

                  <NavLink
                    to={WEB_ROUTES.PLATFORM_INSIGHTS}
                    className="inline-block text-[#69C5D1]"
                  >
                    Learn more
                    <i className="fi fi-rr-arrow-right text-xl float-right mt-0.5 ml-1.5"></i>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-neutral-100 py-16 text-[#091E35] px-8 md:px-0">
          <div className="container mx-auto">
            <div className="mx-auto md:flex">
              <div className="max-w-md">
                <div className="relative">
                  <img
                    className="object-cover"
                    src="/images/istockphoto-1406888053-2048x2048.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="md:pl-16 md:flex items-center">
                <div className="text-base leading-7">
                  <h3 className="pb-5 text-5xl font-semibold hover:underline">
                    <NavLink to={WEB_ROUTES.PROGRAMMES_BURSARY}>
                      PPS Foundation Bursary Programme
                    </NavLink>
                  </h3>
                  <div className="bg-[#FFFAF7] text-[#B09455] border-2 border-[#B09455] py-2 px-5 mb-4 rounded-xl">
                    <strong>Please note</strong> the 2024 PPS Foundation bursary
                    application is closed.
                    <br />
                    2025 applications will open in October 2024.
                  </div>
                  <div className="max-w-2xl">
                    <p className="pb-6">
                      The PPS Foundation Bursary Programme is comprehensive,
                      covering tuition, textbooks, and accommodation for
                      beneficiaries.
                    </p>

                    <p className="pb-6">
                      The bursary is awarded to full-time university students
                      studying towards qualifications in Science, Technology,
                      Engineering, and Mathematics (STEM) fields, based on
                      financial need and academic performance. Beyond financial
                      support, the PPS Foundation collaborates closely with
                      students to provide resources that ensure a seamless and
                      minimally challenging learning and teaching experience.
                    </p>
                    <NavLink
                      to={WEB_ROUTES.PROGRAMMES_BURSARY}
                      className="bg-[#B09455] text-white  py-2 px-5 rounded-full float-start md:mb-0 mb-4 cursor-pointer font-semibold"
                    >
                      Learn more
                      <i className="fi fi-rr-arrow-right text-xl float-right mt-0.5 ml-1.5"></i>
                    </NavLink>
                    <div className="clear-both"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="relative isolate overflow-hidden">
          <video
            loop
            autoplay=""
            muted
            playsinline
            width="100%"
            className="absolute inset-0 -z-10 h-full w-full object-cover"
          >
            <source
              src="/videos/6985525-uhd_3840_2160_25fps.mp4"
              type="video/mp4"
            />
            This browser does not support HTML video.
          </video>

          <div className="h-full w-full bg-gray-900/40 px-8 py-32">
            <div className="container mx-auto text-white">
              <div className="max-w-xl">
                <h3 className="pb-5 text-5xl font-semibold ">Interview ME</h3>
                <p className="mb-6">
                  Interview ME is an innovative feature designed to enhance your
                  job interview skills through virtual practice sessions. Job
                  seekers can conduct mock interviews, which are then reviewed
                  by PPS industry experts.
                </p>
                <div className="clear-both"></div>
                <a
                  href="#."
                  target="_blank"
                  className="bg-[#69C5D1] text-white py-3 px-5 rounded-full cursor-pointer font-semibold"
                >
                  Register ME
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-white py-16 text-[#091E35]">
          <div className="mx-auto container md:px-0 px-8">
            <div className="mx-full mb-5">
              <h2 className="text-5xl md:float-start">
                <span className="font-bold">Latest news </span>
                <span className="font-light"> &amp; insights</span>
              </h2>
              <NavLink
                to={WEB_ROUTES.MEDIA_ARTICLES}
                className="bg-[#69C5D1] text-white py-3 px-5 rounded-full md:float-end cursor-pointer font-semibold hidden md:visible"
              >
                More news
                <i className="fi fi-rr-arrow-right text-xl md:float-right mt-0.5 ml-1.5"></i>
              </NavLink>
              <div className="clear-both"></div>
            </div>
            <p>
              Your comprehensive source for the latest and trending industry and
              career-related content.
            </p>
            <p>
              ChatGPT Stay up-to-date with developments in the career
              development space!
            </p>
            <div className="mx-auto mt-10 mb-5 md:mb-0 grid max-w-2xl grid-cols-1 gap-x-8 md:gap-y-20 gap-y-10 lg:mx-0 lg:max-w-none lg:grid-cols-3">
              {news.slice(0, 3).map((article, i) => (
                <Fragment key={i}>
                  <MediaArticleItem article={article} />
                </Fragment>
              ))}
            </div>
            <NavLink
              to={WEB_ROUTES.MEDIA_ARTICLES}
              className="bg-[#69C5D1] text-white py-3 px-5 rounded-full md:float-end float-start md:mb-0 mb-4 cursor-pointer font-semibold visible md:hidden"
            >
              More news
              <i className="fi fi-rr-arrow-right text-xl float-right mt-0.5 ml-1.5"></i>
            </NavLink>
            <div className="clear-both"></div>
          </div>
        </section>

        <section className="bg-neutral-100 py-20 text-[#091E35]">
          <div className="mx-auto container md:px-0 px-8">
            <div className="grid grid-cols-1 gap-10 lg:grid-cols-12 lg:gap-8">
              <div className="max-w-xl  sm:text-4xl lg:col-span-7">
                <h2 className="text-4xl font-semibold mb-5">Stay up to date</h2>
                <p className="text-2xl pl-5 border-l-2 border-[#B09455]">
                  Sign up for our mailing list to receive the latest
                  Professionals Connect news and related information by
                  completing the subscription form.
                </p>
                <p className="mt-4 text-sm">
                  You can unsubscribe from our mailing list at any time using
                  the link in our emails. For information on how we process your
                  personal data, please review our{` `}
                  <a
                    href="https://www.pps.co.za/themes/custom/refresh/assets/pdf/TERMS%20AND%20CONDITIONS%20(1).pdf"
                    target="_blank"
                    className="hover:underline"
                  >
                    privacy&nbsp;policy
                  </a>
                  .
                </p>
                <p className="mt-4 text-sm text-gray-500">
                  This form is protected by reCAPTCHA. Google's{" "}
                  <a
                    href="https://policies.google.com/privacy?hl=en-GB"
                    target="_blank"
                    className="hover:underline"
                  >
                    Privacy Policy
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://policies.google.com/terms?hl=en-GB"
                    target="_blank"
                    className="hover:underline"
                  >
                    Terms of Service
                  </a>{" "}
                  apply.
                </p>
              </div>
              <FormNewsletter bgType={`light`} />
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
