import { useState, useEffect, useContext } from "react";
import { useMatch } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { WEB_ROUTES } from "../routes/web-routes";
import Header from "../components/header";
import Footer from "../components/footer";
import ProgrammeFooter from "../components/programme-footer";

export default function ProgrammesGIDP() {
  return (
    <>
      <Header />
      <main>
        <section
          className="bg-white py-60 relative bg-cover bg-top"
          style={{
            backgroundImage: "url(/images/programme-gidp.png)",
          }}
        >
          <div className="absolute bottom-0 left-0 bg-black bg-opacity-50 w-full text-base leading-7 text-white md:px-0 px-8">
            <div className="container h-full mx-auto py-10 ">
              <div className="mx-auto">
                <h1 className="text-5xl font-semibold">
                  Graduate Internship Development Programme
                </h1>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-white py-16">
          <div className="container mx-auto md:px-0 px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
              <div>
                <div className="text-base leading-7 text-gray-700">
                  <h2 className="text-4xl  mb-4">
                    PPS has an exciting opportunity for young, ambitious
                    graduates to join our dynamic team for a one-year Graduate
                    Internship Development Programme (GIDP). At the end of the
                    programme, interns will have the prospect of receiving
                    permanent employment within PPS.
                  </h2>
                </div>
              </div>
              <div>
                <p className="mb-4">
                  The Graduate Internship Development Programme aims to instil
                  graduates with much-needed skills to build a foundation for
                  their professional careers. Since 2015, over 100 graduates
                  have completed the programme.
                </p>
                <p className="mb-4">
                  Minimum requirements for the programme include a three- or
                  four-year university or university of technology
                  qualification. The 12-month long programme provides on-the-job
                  experience; behavioural training; extensive external training
                  with reputable organisations on modules that will contribute
                  to personal and career development, mentorship and coaching
                  opportunities.
                </p>
                <a
                  href="https://ppsfoundation.xfour.co.za/"
                  target="_blank"
                  className="bg-[#B09455] text-white  py-2 px-5 rounded-full float-start cursor-pointer font-semibold mt-4"
                >
                  Find a Job
                  <i className="fi fi-rr-arrow-right text-xl float-right mt-0.5 ml-1.5"></i>
                </a>
              </div>
            </div>

            <div className="pt-20 mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
              <iframe
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen=""
                frameborder="0"
                height="400"
                src="https://www.youtube.com/embed/s4AId1Lw664"
                title="YouTube video player"
                width="100%"
                className="rounded-xl"
              ></iframe>

              <iframe
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen=""
                frameborder="0"
                height="400"
                src="https://www.youtube.com/embed/LBDiSKORi38"
                title="YouTube video player"
                width="100%"
                className="rounded-xl"
              ></iframe>
            </div>
          </div>
        </section>

        <ProgrammeFooter />
      </main>
      <Footer />
    </>
  );
}
