import { NavLink } from "react-router-dom";
import { WEB_ROUTES } from "../routes/web-routes";

export default function ProgrammeFooter() {
  return (
    <>
      <section className="relative isolate overflow-hidden">
        <img
          src="/images/footer.jpg"
          alt=""
          className="absolute inset-0 -z-10 h-full w-full object-cover"
        />
        <div className="h-full w-full bg-gray-900/40 px-8 py-16">
          <div className="container mx-auto text-center max-w-2xl">
            <h2 className="text-4xl text-white">
              <span className="font-bold">Connecting Job Seekers</span>
              <br />
              for a better tomorrow.
            </h2>
            <p className="mb-10 text-white">
              Recent graduates and entry-level professionals can select a range
              of job opportunities provided on the portal to connect with
              potential employers from various industries.
            </p>

            <NavLink
              to={WEB_ROUTES.JOBS}
              className="bg-[#B09455] text-white py-2 px-5 rounded-full cursor-pointer font-semibold inline-block"
            >
              Apply for a job{" "}
              <i className="fi fi-rr-arrow-right text-xl float-right mt-0.5 ml-1.5"></i>
            </NavLink>
          </div>
        </div>
      </section>
    </>
  );
}
