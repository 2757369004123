export const WEB_ROUTES = {
  HOME: `/`,
  ABOUT: `/about`,
  FOUNDATION: `/about/pps-foundation`,
  TESTIMONIALS: `/about/testimonials`,

  PLATFORM: `/platform`,
  PLATFORM_MENTORSHIP: `/platform/mentorship`,
  PLATFORM_WORK_READINESS: `/platform/work-readiness`,
  PLATFORM_FINANCIAL: `/platform/financial-literacy`,
  PLATFORM_JOB_OPPORTUNITIES: `/platform/job-opportunities`,
  PLATFORM_CV_BUILDER: `/platform/cv-builder`,
  PLATFORM_INSIGHTS: `/platform/insights`,

  PROGRAMMES: `/programmes`,
  PROGRAMMES_BURSARY: `/programmes/bursary-programme`,
  PROGRAMMES_USP: `/programmes/university-support-programme`,
  PROGRAMMES_GIDP: `/programmes/graduate-internship-development-programme`,
  PROGRAMMES_LEAP: `/programmes/leap-programme`,

  MEDIA_ARTICLES: `/media/articles`,
  MEDIA_VIDEOS: `/media/videos`,
  MEDIA_INSIGHTS: `/media/insights`,
  MEDIA_WORK_READINESS: `/media/work-readiness`,
  MEDIA_EVENTS: `/media/events`,

  JOBS: `/jobs`,
  CONTACT: `/contact-us`,
  NEWSLETTER: `/newsletter`,
  LEGAL: `/legal`,
  TERMS: `/legal/terms-of-use`,
  NOT_FOUND: `/404`,
};
