import { Fragment } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import MediaVideoItem from "../components/media-video-item";

export default function MediaVideos() {
  const mediaVideos = [
    {
      title: "Work-readiness post-COVID-19",
      snippet:
        "CEO of Whole Person Academy, Cheryl Benadie, shares what young professionals should do to prepare for the world of work post-COVID.",
      video:
        '<video controls="false" height="300" width="100%"><source src="https://www.professionalsconnect.co.za/themes/professionalsconnect/templates/assets/video/PPSWorkReadiness.mp4" type="video/mp4"></video>',
      date: "24 Nov<br/>2020",
      description: "<p class='mb-4'></p>",
    },

    {
      title: "2018 Graduate Internship Programme",
      snippet:
        "The learning never stops and this is why Nerisha chose our graduate programme.",
      video:
        '<iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" frameborder="0"  height="200" width="100%" sandbox="allow-same-origin allow-scripts allow-popups" src="https://www.youtube.com/embed/videoseries?list=PLLVxjLc_vY4TcU4HdZNBTBA8hNUX-Tywl&amp;rel=0"></iframe>',
      date: "7 Sep<br/>2020",
      description: "<p class='mb-4'></p>",
    },
    {
      title: "PPS Foundation 2019 Annual Report",
      snippet:
        "PPS Foundation 2019 Annual Report - Dr Sybil Seoka - PPS Foundation Chairman",
      video:
        '<iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" frameborder="0"  height="200" width="100%" sandbox="allow-same-origin allow-scripts allow-popups" src="https://www.youtube.com/embed/videoseries?list=PLLVxjLc_vY4RiWwehb5PkBWNeDa6qqsFL&amp;rel=0"></iframe>',
      date: "7 Sep<br/>2020",
      description: "<p class='mb-4'></p>",
    },
  ];

  return (
    <>
      <Header />
      <main>
        <section className="bg-white py-16">
          <div className="container mx-auto md:px-0 px-8">
            <div className="text-base leading-7 text-gray-700 items-center md:flex">
              <h1 className="text-5xl font-semibold items-center flex mb-4 md:mb-0">
                <i className="fi fi-br-video-duration text-7xl mr-4"></i> Videos
              </h1>
              <span className="ml-auto bg-[#A76952] text-white md:float-end float-start px-4 py-2 rounded-full cursor-pointer">
                <i className="fi fi-rr-bell-ring"></i> Subscribe to videos &
                podcasts
              </span>
              <div className="clear-both"></div>
            </div>
          </div>
        </section>

        <section className="bg-white pb-16">
          <div className="container mx-auto md:px-0 px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
              {mediaVideos.slice(0, 3).map((article, i) => (
                <Fragment key={i}>
                  <MediaVideoItem article={article} />
                </Fragment>
              ))}
            </div>
          </div>
        </section>

        <section className="bg-white pb-16">
          <div className="container mx-auto md:px-0 px-8">
            <div className="text-base leading-7 text-gray-700">
              <h2 className="text-5xl font-semibold items-center flex">
                <i className="fi fi-rr-music-file text-7xl mr-4"></i> Podcasts
              </h2>
            </div>
          </div>
        </section>

        <section className="bg-white pb-16">
          <div className="container mx-auto md:px-0 px-8">
            <div className="grid lg:grid-cols-12">
              <div className="col-span-12 text-center mb-2">
                <p className="mb-4">
                  A podcast by PPS for young professionals that highlights the
                  steps one should take to run further and faster on the road to
                  financial freedom.
                </p>
                <iframe
                  frameborder="0"
                  height="480px"
                  seamless=""
                  src="https://player.simplecast.com/030a60d9-62b8-4d1f-9e47-9f5685287e3e?dark=false&amp;show=true"
                  width="100%"
                  data-gtm-yt-inspected-14="true"
                ></iframe>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-white pb-16">
          <div className="container mx-auto md:px-0 px-8">
            <div className="grid lg:grid-cols-12">
              <div className="col-span-12 mb-2">
                <p className="mb-4">
                  Let's face it: in your 20's things are gonna break, others
                  will get lost, some stolen.
                </p>
                <p className="mb-4">This shouldn't stop you from living.</p>
                <p className="mb-4">These are part of life's little lessons.</p>

                <iframe
                  allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                  allowFullScreen=""
                  frameBorder="0"
                  height="352"
                  loading="lazy"
                  src="https://open.spotify.com/embed/show/6KG0PVibB3RcBUjSe9yJ9I?utm_source=generator"
                  width="100%"
                  data-gtm-yt-inspected-14="true"
                ></iframe>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-white pb-16">
          <div className="container mx-auto md:px-0 px-8">
            <div className="grid lg:grid-cols-12">
              <div className="col-span-12 mb-2">
                <p className="mb-4">
                  A podcast about money management and personal finance.
                </p>

                <iframe
                  allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                  allowFullScreen=""
                  frameBorder="0"
                  height="152"
                  loading="lazy"
                  src="https://open.spotify.com/embed/show/28bxNNR9QnVhPcDEihkcG5?utm_source=generator"
                  width="100%"
                  data-gtm-yt-inspected-14="true"
                ></iframe>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
