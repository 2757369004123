import { useState, useEffect, useContext } from "react";
import { useMatch } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { WEB_ROUTES } from "../routes/web-routes";
import Header from "../components/header";
import Footer from "../components/footer";
import ProgrammeFooter from "../components/programme-footer";

export default function ProgrammesUSP() {
  return (
    <>
      <Header />
      <main>
        <section
          className="bg-white py-60 relative bg-cover bg-top"
          style={{
            backgroundImage: "url(/images/programme-university-support.png)",
          }}
        >
          <div className="absolute bottom-0 left-0 bg-black bg-opacity-50 w-full text-base leading-7 text-white md:px-0 px-8">
            <div className="container h-full mx-auto py-10 ">
              <div className="mx-auto">
                <h1 className="text-5xl font-semibold">
                  University Support Programme
                </h1>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-white py-16">
          <div className="container mx-auto md:px-0 px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
              <div>
                <div className="text-base leading-7 text-gray-700">
                  <h2 className="text-4xl  mb-4">
                    As our socio-economic development landscape evolves, the PPS
                    Foundation looks to adapt and engage in partnerships that
                    benefit our stakeholders and beneficiaries.
                  </h2>
                </div>
              </div>
              <div>
                <p>
                  The University Support Programme focuses on historically
                  disadvantaged institutions and facilitates those initiatives
                  whose intended outcomes align with both the national interest
                  and South Africa's socioeconomic agenda. Aiding the
                  development of STEM professionals, which the country sorely
                  needs, constitutes a central focus of this agenda. From
                  computer labs to feeding programmes, the PPS Foundation aids
                  positive change in the teaching and learning experience; it
                  uplifts previously disadvantaged students and empowers
                  educators with the tools they need to deliver world-className
                  education.
                </p>
                <a
                  href="mailto:infoppsfoundation@pps.co.za?subject=Enquire about the University Support Programme"
                  target="_blank"
                  className="bg-[#B09455] text-white  py-2 px-5 rounded-full float-start cursor-pointer font-semibold mt-4"
                >
                  Enquire about the University Support Programme
                  <i className="fi fi-rr-arrow-right text-xl float-right mt-0.5 ml-1.5"></i>
                </a>
              </div>
            </div>

            <div className="pt-20 mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
              <img
                className="h-full w-full object-cover rounded-xl"
                src="/images/programmes/uni-support-1.png"
              />
              <img
                className="h-full w-full object-cover rounded-xl"
                src="/images/programmes/uni-support-2.png"
              />
            </div>
          </div>
        </section>

        <ProgrammeFooter />
      </main>
      <Footer />
    </>
  );
}
