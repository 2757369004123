import { useState, useEffect } from "react";
import { useMatch } from "react-router-dom";
import FormNewsletter from "../components/form-newsletter";
import Header from "../components/header";
import Footer from "../components/footer";

export default function Newsletter() {
  return (
    <>
      <Header />
      <main>
        <section className="bg-white py-16">
          <div className="container mx-auto md:px-0 px-8">
            <div className="text-base leading-7 text-gray-700">
              <h1 className="pb-5 text-5xl font-semibold">
                Sign up for our mailing list
              </h1>

              <div className="md:flex gap-x-10">
                <article className="max-w-4xl md:mb-0 mb-8">
                  <p className="text-2xl">
                    Sign up for our mailing list to receive the latest
                    Professionals Connect news and related information by
                    completing the subscription form.
                  </p>
                  <p className="mt-4 text-sm text-gray-500">
                    You can unsubscribe from our mailing list at any time using
                    the link in our emails. For information on how we process
                    your personal data, please review our{" "}
                    <a
                      className="hover:underline"
                      href="https://www.pps.co.za/themes/custom/refresh/assets/pdf/TERMS%20AND%20CONDITIONS%20(1).pdf"
                      target="_blank"
                    >
                      privacy&nbsp;policy
                    </a>
                    .
                  </p>
                  <p className="mt-4 text-sm text-gray-500">
                    This form is protected by reCAPTCHA. Google's{" "}
                    <a
                      href="https://policies.google.com/privacy?hl=en-GB"
                      target="_blank"
                      className="hover:underline"
                    >
                      Privacy Policy
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://policies.google.com/terms?hl=en-GB"
                      target="_blank"
                      className="hover:underline"
                    >
                      Terms of Service
                    </a>{" "}
                    apply.
                  </p>
                </article>
                <FormNewsletter bgType={`light`} />
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
