import { useState, useEffect } from "react";
import { useMatch } from "react-router-dom";
import { WEB_ROUTES } from "../routes/web-routes";
import Header from "../components/header";
import Footer from "../components/footer";

export default function Page() {
  const singlePage = useMatch(WEB_ROUTES.LEGAL + "/:page");
  const [page, setPage] = useState({});

  useEffect(() => {
    const pages = [
      {
        title: "Terms of use",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        path: WEB_ROUTES.TERMS,
        image: "https://placehold.co/1200x1200",
        banner: "https://placehold.co/1200x1200",
      },
      {
        title: "Privacy policy",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        path: WEB_ROUTES.PRIVACY_POLICY,
        image: "https://placehold.co/1200x1200",
        banner: "https://placehold.co/1200x1200",
      },
      {
        title: "Cookie policy",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        path: WEB_ROUTES.COOKIE_POLICY,
        image: "https://placehold.co/1200x1200",
        banner: "https://placehold.co/1200x1200",
      },
      {
        title: "Protection of Personal Information Act (POPI) Disclaimer",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        path: WEB_ROUTES.POPI,
        image: "https://placehold.co/1200x1200",
        banner: "https://placehold.co/1200x1200",
      },
      {
        title: "Copyright Statement",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        path: WEB_ROUTES.COPYRIGHT_STATEMENT,
        image: "https://placehold.co/1200x1200",
        banner: "https://placehold.co/1200x1200",
      },
    ];

    const pageFound = pages.filter(
      (page) => page.path === singlePage?.pathnameBase
    );

    if (pageFound.length !== 0) {
      setPage(pageFound[0]);
    }
  }, [singlePage]);

  return (
    <>
      <Header />
      <main>
        <section className="bg-white py-16">
          <div className="container mx-auto md:px-0 px-8">
            <div className="text-base leading-7 text-gray-700">
              <h1 className="pb-5 text-5xl font-semibold">{page?.title}</h1>
              <div className="w-20 border-b-4 border-red-600 mb-5"></div>
              <div className="max-w-4xl">{page?.description}</div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
