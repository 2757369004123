import { useState, useEffect } from "react";
import { useMatch } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { WEB_ROUTES } from "../routes/web-routes";
import Header from "../components/header";
import Footer from "../components/footer";

export default function PlatformSingle() {
  const singlePlatform = useMatch(WEB_ROUTES.PLATFORM + "/:platform");
  const [platform, setPlatform] = useState({});

  useEffect(() => {
    const pages = [
      {
        title: "Mentorship",
        icon: (
          <div className="font-semibold text-white text-5xl float-start md:float-none rounded-full bg-[#70758C] py-5 px-5 mr-5 cursor-pointer">
            <div className="w-20 h-20 flex items-center justify-center">
              <i className="fi fi-rr-referral-user "></i>
            </div>
          </div>
        ),
        description: (
          <div>
            <p className="mb-4">
              Unlock your full potential with our expert mentorship program,
              designed to provide personalized guidance from seasoned
              professionals in your industry. Our platform carefully matches
              mentees with mentors who align with their career aspirations,
              ensuring meaningful and impactful mentorship experiences. Whether
              you're looking to enhance your skills, gain insights into your
              chosen field, or navigate career challenges, our mentorship
              program offers the support and expertise you need. Learn more
              about how connecting with the right mentor can accelerate your
              professional growth and help you achieve your goals.
            </p>
            <p className="mb-4">
              <span className="font-bold">Mentorship by Experts</span>
              <br />
              Gain insights and guidance from industry leaders who are experts
              in their fields.
              <br />
              <br />
              <span className="font-bold">Mentorship Matched with Mentees</span>
              <br />
              Personalized mentoring that pairs you with professionals aligned
              with your career goals. <br />
              <br />
              <span className="font-bold">Learn More</span>
              <br />
              Discover how mentorship can accelerate your career and help you
              reach your potential.
            </p>
          </div>
        ),
        path: WEB_ROUTES.PLATFORM_MENTORSHIP,
        image: "/illustrations/iStock-2152332118.jpg",
      },
      {
        title: "Work Readiness",
        icon: (
          <div className="font-semibold text-white text-5xl float-start md:float-none rounded-full bg-[#BF785E] py-5 px-5 mr-5 cursor-pointer">
            <div className="w-20 h-20 flex items-center justify-center">
              <i className="fi fi-rr-skill-user"></i>
            </div>
          </div>
        ),
        description: (
          <div>
            <p className="mb-4">
              Prepare for the job market with our comprehensive work readiness
              resources. Gain essential interview preparation skills, access
              virtual materials, and receive career guidance tailored to your
              needs. Our platform also provides crucial insights into workspace
              etiquette, ensuring you're fully equipped for professional
              success. Learn more about how our work readiness program can give
              you the competitive edge in your career journey.
            </p>
            <p className="mb-4">
              <span className="font-bold">Interview Preparation Skills</span>
              <br />
              Master the art of interviews with practical tips and expert
              advice. <br />
              <br />
              <span className="font-bold">Virtual Access to Materials</span>
              <br />
              Access a comprehensive library of resources to prepare for the
              workforce from anywhere. <br />
              <br />
              <span className="font-bold">
                Career Guidance & Workspace Etiquette
              </span>
              <br /> Get the knowledge you need to navigate your career and
              maintain professionalism at work. <br />
              <br />
              <span className="font-bold">Learn More</span>
              <br />
              Equip yourself with the tools and knowledge to succeed in today's
              job market.
            </p>
          </div>
        ),
        path: WEB_ROUTES.PLATFORM_WORK_READINESS,
        image: "/illustrations/iStock-1275949384.jpg",
      },
      {
        title: "Financial Literacy",
        icon: (
          <div className="font-semibold text-white text-5xl float-start md:float-none rounded-full bg-[#81957E] py-5 px-5 mr-5 cursor-pointer">
            <div className="w-20 h-20 flex items-center justify-center">
              <i className="fi fi-rr-chart-histogram"></i>
            </div>
          </div>
        ),
        description: (
          <div>
            <p className="mb-4">
              Empower yourself with financial literacy through our targeted
              resources on budgeting, financial education, and planning. Learn
              practical budgeting techniques, build a strong foundation in
              financial knowledge, and develop a personalized financial plan to
              secure your future. Explore our comprehensive financial literacy
              offerings and take control of your financial well-being today.
            </p>
            <p className="mb-4">
              <span className="font-bold">Budgeting</span>
              <br />
              Learn to manage your finances effectively with practical budgeting
              techniques.
              <br />
              <br />
              <span className="font-bold">Financial Education</span>
              <br />
              Build your financial knowledge with our educational resources and
              tools.
              <br />
              <br />
              <span className="font-bold">Financial Planning</span>
              <br />
              Develop a robust financial plan that supports your long-term
              goals.
              <br />
              <br />
              <span className="font-bold">Learn More</span>
              <br />
              Empower yourself with financial literacy to make informed
              decisions and secure your future.
            </p>
          </div>
        ),
        path: WEB_ROUTES.PLATFORM_FINANCIAL,
        image: "/illustrations/iStock-1214054809.jpg",
      },
      {
        title: "Job Opportunities",
        icon: (
          <div className="font-semibold text-white text-5xl float-start md:float-none rounded-full bg-[#B09455] py-5 px-5 mr-5 cursor-pointer">
            <div className="w-20 h-20 flex items-center justify-center">
              <i className="fi fi-rr-briefcase"></i>
            </div>
          </div>
        ),
        description: (
          <div>
            <p className="mb-4">
              Discover your next career move with our curated job opportunities.
              Our platform features up-to-date job adverts, a streamlined
              application process, and advanced filtered search capabilities.
              Whether you're searching for a role that aligns with your
              qualifications or seeking to apply directly on-site, our job
              opportunities section connects you with the right employers. Learn
              more about how you can land your ideal job through our platform.
            </p>
            <p className="mb-4">
              <span className="font-bold">Job Adverts</span>
              <br />
              Explore the latest job openings tailored to your qualifications
              and interests.
              <br />
              <br />
              <span className="font-bold">Same Site Applications</span>
              <br />
              Apply directly through our platform for convenience and
              efficiency.
              <br />
              <br />
              <span className="font-bold">Filtered Job Search</span>
              <br />
              Utilize advanced search features to find the perfect job that
              matches your skills and preferences.
              <br />
              <br />
              <span className="font-bold">Learn More</span>
              <br />
              Unlock your career potential by exploring a variety of job
              opportunities.
            </p>
          </div>
        ),
        path: WEB_ROUTES.PLATFORM_JOB_OPPORTUNITIES,
        image: "/illustrations/iStock-1059233776.jpg",
      },
      {
        title: "CV Builder",
        icon: (
          <div className="font-semibold text-white text-5xl float-start md:float-none rounded-full bg-[#5D465D] py-5 px-5 mr-5 cursor-pointer">
            <div className="w-20 h-20 flex items-center justify-center">
              <i className="fi fi-rr-resume"></i>
            </div>
          </div>
        ),
        description: (
          <div>
            <p className="mb-4">
              Create a standout professional CV with our intuitive CV builder,
              tailored for professionals looking to make an impact. Our platform
              offers an automatic CV generator with multiple templates,
              including video options, to help you craft a resume that
              highlights your strengths. Simplify the CV creation process and
              present yourself confidently in the job market. Learn more about
              our CV builder and how it can enhance your professional profile.
            </p>
            <p className="mb-4">
              <span className="font-bold">Integrated for Professionals</span>
              <br />
              Create a professional CV that stands out in the competitive job
              market.
              <br />
              <br />
              <span className="font-bold">Connect Automatic CV Generator</span>
              <br />
              Use our tool to automatically generate a CV that highlights your
              strengths.
              <br />
              <br />
              <span className="font-bold">
                Multiple Templates / Video Options
              </span>
              <br />
              Choose from a variety of templates, including video CVs, to
              showcase your unique qualifications.
              <br />
              <br />
              <span className="font-bold">Learn More</span>
              <br />
              Simplify the process of building a CV with our user-friendly
              tools.
            </p>
          </div>
        ),
        path: WEB_ROUTES.PLATFORM_CV_BUILDER,
        image: "/illustrations/iStock-1265169817.jpg",
      },
      {
        title: "Blog Insights",
        icon: (
          <div className="font-semibold text-white text-5xl float-start md:float-none rounded-full bg-[#A25C5C] py-5 px-5 mr-5 cursor-pointer">
            <div className="w-20 h-20 flex items-center justify-center">
              <i className="fi fi-rr-web-design"></i>
            </div>
          </div>
        ),
        description: (
          <div>
            <p className="mb-4">
              Stay informed and ahead in your career with our Blog Insights.
              Access a wealth of blog articles and industry insights that
              provide valuable knowledge tailored to your field of study.
              Receive notifications based on your interests, ensuring you never
              miss out on critical updates. Explore our regularly updated
              content and deepen your understanding of industry trends and
              professional development. Learn more by diving into our blog
              insights today.
            </p>
            <p className="mb-4">
              <span className="font-bold">Blog Articles</span>
              <br />
              Stay informed with articles that cover the latest trends and
              insights in your industry.
              <br />
              <br />
              <span className="font-bold">Industry Insights</span>
              <br />
              Gain a deeper understanding of your field with curated content
              from industry experts.
              <br />
              <br />
              <span className="font-bold">
                Notifications (based on study fields)
              </span>
              <br />
              Receive updates and notifications tailored to your specific study
              field and interests.
              <br />
              <br />
              <span className="font-bold">Learn More</span>
              <br />
              Explore a wealth of knowledge through our regularly updated blog
              content. Let me know if there are any specific details you'd like
              to add or adjust!
            </p>
          </div>
        ),
        path: WEB_ROUTES.PLATFORM_INSIGHTS,
        image: "/illustrations/iStock-1331615284.jpg",
      },
    ];

    const pageFound = pages.filter(
      (platform) => platform.path === singlePlatform?.pathnameBase
    );

    if (pageFound.length !== 0) {
      setPlatform(pageFound[0]);
    }
  }, [singlePlatform]);

  return (
    <>
      <Header />
      <main>
        <section className="bg-white pt-16 ">
          <div className="container mx-auto md:px-0 px-8">
            <div className="text-base leading-7 text-gray-700 items-center md:flex">
              <h1 className="pb-5 text-5xl font-semibold items-center md:flex">
                {platform?.icon}
                <div className="clear-both visible md:hidden"></div>
                {platform?.title}
              </h1>
              <div className="ml-auto md:float-end">
                <a
                  href="https://rms.bluevinegroup.co.za/register/Candidate"
                  target="_blank"
                  className="bg-[#69C5D1] text-white py-3 px-5 md:float-end float-start md:my-0 my-1 rounded-full cursor-pointer font-semibold"
                >
                  Register on our platform
                  <i className="fi fi-rr-arrow-right text-xl float-right mt-0.5 ml-1.5"></i>
                </a>
                <NavLink
                  to={WEB_ROUTES.PLATFORM}
                  className="bg-[#B09455] text-white py-3 px-5 mr-2 md:float-end float-start md:my-0 my-1 rounded-full cursor-pointer font-semibold"
                >
                  Other features
                </NavLink>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-white">
          <div className="container mx-auto relative isolate overflow-hidden md:pb-40 pb-16">
            <img
              className="md:absolute inset-0 -z-10 md:w-9/12 w-full object-cover md:-ml-10 md:-mt-5"
              src={platform?.image}
              alt={platform?.title}
            />
            <div className="bg-white bg-opacity-90 md:w-6/12 w-full px-10 md:py-10 py-0 float-end rounded-lg">
              {platform?.description}
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
