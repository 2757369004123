import { NavLink } from "react-router-dom";
import { WEB_ROUTES } from "../routes/web-routes";

export default function JobItem({ job }) {
  return (
    <>
      <div className="flex flex-col items-start p-5 bg-[#FFFAF7] border-2 rounded-xl border-neutral-100">
        <div className="relative w-full">
          <div className="float-start bg-[#091E35] p-2 text-xs font-semibold rounded-lg ">
            <span className="text-white">
              Posted:{" "}
              <span
                dangerouslySetInnerHTML={{
                  __html: job?.date,
                }}
              />
            </span>
          </div>
          <div className="float-end text-xs font-semibold ">
            <span className="text-white">
              <span
                dangerouslySetInnerHTML={{
                  __html: job?.tags,
                }}
              />
            </span>
          </div>
        </div>
        <div className="max-w-xl">
          <div className="relative">
            <h3 className="mt-4 text-2xl text-gray-900 font-semibold">
              <a
                className="hover:underline"
                href="https://rms.bluevinegroup.co.za/"
                target="_blank"
              >
                {job.title}
              </a>
            </h3>
            <p className=" mt-3 mb-5">{job.snippet}</p>
            <a
              href="https://rms.bluevinegroup.co.za/"
              target="_blank"
              className="bg-[#FFFAF7] text-[#B09455] border-2 border-[#B09455] py-2 px-5 rounded-full float-start cursor-pointer font-semibold"
            >
              Apply
              <i className="fi fi-rr-arrow-right text-xl float-right mt-0.5 ml-1.5"></i>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
