export default function FormNewsletter({ bgType }) {
  return (
    <>
      <form className="w-full lg:col-span-5">
        <div className="mb-4">
          <input
            name="fname"
            type="text"
            required
            className={`w-full flex-auto border-0 px-3.5 py-2 shadow-sm ring-0 focus:ring-0 placeholder-neutral-400 ${
              bgType === "dark"
                ? `bg-zinc-300/5 text-white`
                : `bg-zinc-200 text-zinc-500`
            }`}
            placeholder="First name"
          />
        </div>
        <div className="mb-4">
          <input
            name="lname"
            type="text"
            required
            className={`w-full flex-auto border-0 px-3.5 py-2 shadow-sm ring-0 focus:ring-0 placeholder-neutral-400 ${
              bgType === "dark"
                ? `bg-zinc-300/5 text-white`
                : `bg-zinc-200 text-zinc-500`
            }`}
            placeholder="Last name"
          />
        </div>
        <div className="mb-4">
          <input
            name="qualification"
            type="text"
            required
            className={`w-full flex-auto border-0 px-3.5 py-2 shadow-sm ring-0 focus:ring-0 placeholder-neutral-400 ${
              bgType === "dark"
                ? `bg-zinc-300/5 text-white`
                : `bg-zinc-200 text-zinc-500`
            }`}
            placeholder="Highest qualification"
          />
        </div>

        <div className="flex gap-x-4">
          <label htmlFor="email-address" className="sr-only">
            Email address
          </label>
          <input
            name="email"
            type="email"
            autoComplete="email"
            required
            className={`w-full flex-auto border-0 px-3.5 py-2 shadow-sm ring-0 focus:ring-0 placeholder-neutral-400 ${
              bgType === "dark"
                ? `bg-zinc-300/5 text-white`
                : `bg-zinc-200 text-zinc-500`
            }`}
            placeholder="Email address"
          />
          <button
            type="submit"
            className="flex-none bg-[#091E35] rounded-md px-5 py-2 text-white inline-block"
          >
            Sign up
          </button>
        </div>
      </form>
    </>
  );
}
