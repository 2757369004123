import { NavLink } from "react-router-dom";
import { WEB_ROUTES } from "../routes/web-routes";
import Header from "../components/header";
import Footer from "../components/footer";

export default function Programmes() {
  return (
    <>
      <Header />
      <main>
        <section className="bg-white py-16">
          <div className="container mx-auto md:px-0 px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
              <div className="text-base leading-7 text-gray-700">
                <h1 className="md:mb-5 mb-0 text-5xl font-semibold">
                  Our programmes
                </h1>
              </div>
            </div>
          </div>
        </section>

        <section id="mission-vision" className="bg-white pb-16">
          <div className="mx-auto container">
            <div className="mx-auto">
              <dl className="grid max-w-xl grid-cols-1 lg:max-w-none lg:grid-cols-2">
                <div className="relative bg-[#FFFAF7] p-10">
                  <dt>
                    <NavLink to={WEB_ROUTES.PROGRAMMES_BURSARY}>
                      <img
                        className="mb-5 w-full rounded-xl"
                        src="/images/programme-busary.png"
                      />
                    </NavLink>
                    <h2 className="text-2xl font-semibold text-gray-900">
                      <NavLink
                        className="hover:underline hover:cursor-pointer"
                        to={WEB_ROUTES.PROGRAMMES_BURSARY}
                      >
                        PPS Foundation Bursary Programme
                      </NavLink>
                    </h2>
                  </dt>
                  <dd className="mt-2 text-base text-gray-600">
                    <p className="mb-4">
                      The PPS Foundation is committed to improving access to
                      education and making a measurable contribution to South
                      Africa's sustainable development by growing the
                      professional pipeline. We support tertiary students who
                      demonstrate meritorious academic achievements, awarding
                      bursaries from the second year up to Honours level.
                    </p>
                    <NavLink
                      to={WEB_ROUTES.PROGRAMMES_BURSARY}
                      className="bg-[#B09455] text-white mr-2 py-2 px-5 rounded-full float-start cursor-pointer font-semibold"
                    >
                      Read more
                    </NavLink>
                  </dd>
                </div>
                <div className="relative bg-white p-10">
                  <dt>
                    <NavLink to={WEB_ROUTES.PROGRAMMES_USP}>
                      <img
                        className="mb-5 w-full rounded-xl"
                        src="/images/programme-university-support.png"
                      />
                    </NavLink>
                    <h2 className="text-2xl font-semibold text-gray-900">
                      <NavLink
                        className="hover:underline hover:cursor-pointer"
                        to={WEB_ROUTES.PROGRAMMES_USP}
                      >
                        University Support Programme
                      </NavLink>
                    </h2>
                  </dt>
                  <dd className="mt-2 text-base leading-7 text-gray-600">
                    <p className="mb-4">
                      Our University Support Programme focuses on driving
                      positive change in historically disadvantaged
                      institutions. We build strong partnerships with
                      universities and organisations to address obstacles that
                      hinder students from having a fulfilling learning
                      experience.
                    </p>
                    <NavLink
                      to={WEB_ROUTES.PROGRAMMES_USP}
                      className="bg-[#B09455] text-white mr-2 py-2 px-5 rounded-full float-start cursor-pointer font-semibold"
                    >
                      Read more
                    </NavLink>
                  </dd>
                </div>
                <div className="relative md:bg-white bg-[#FFFAF7] p-10">
                  <dt>
                    <NavLink to={WEB_ROUTES.PROGRAMMES_GIDP}>
                      <img
                        className="mb-5 w-full rounded-xl"
                        src="/images/programme-gidp.png"
                      />
                    </NavLink>
                    <h2 className="text-2xl font-semibold text-gray-900">
                      <NavLink
                        className="hover:underline hover:cursor-pointer"
                        to={WEB_ROUTES.PROGRAMMES_GIDP}
                      >
                        Graduate Internship Development Programme
                      </NavLink>
                    </h2>
                  </dt>
                  <dd className="mt-2 text-base leading-7 text-gray-600">
                    <p className="mb-4">
                      The Graduate Internship Development Programme aims to
                      equip graduates with essential skills to build a solid
                      foundation for their professional careers. Since 2015,
                      over 100 graduates have successfully completed the
                      programme.
                    </p>
                    <NavLink
                      to={WEB_ROUTES.PROGRAMMES_GIDP}
                      className="bg-[#B09455] text-white mr-2 py-2 px-5 rounded-full float-start cursor-pointer font-semibold"
                    >
                      Read more
                    </NavLink>
                  </dd>
                </div>
                <div className="relative md:bg-[#FFFAF7] bg-white p-10">
                  <dt>
                    <NavLink to={WEB_ROUTES.PROGRAMMES_LEAP}>
                      <img
                        className="mb-5 w-full rounded-xl"
                        src="/images/programme-leap.png"
                      />
                    </NavLink>
                    <h2 className="text-2xl font-semibold text-gray-900">
                      <NavLink
                        className="hover:underline hover:cursor-pointer"
                        to={WEB_ROUTES.PROGRAMMES_LEAP}
                      >
                        LEAP Work-Readiness Programme
                      </NavLink>
                    </h2>
                  </dt>
                  <dd className="mt-2 text-base leading-7 text-gray-600">
                    <p className="mb-4">
                      Having identified the need for our beneficiaries and
                      graduates at large to receive support in their transition
                      from tertiary education to the workplace, the PPS
                      Foundation developed LEAP.
                    </p>
                    <NavLink
                      to={WEB_ROUTES.PROGRAMMES_LEAP}
                      className="bg-[#B09455] text-white mr-2 py-2 px-5 rounded-full float-start cursor-pointer font-semibold"
                    >
                      Read more
                    </NavLink>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
