import { useState, useEffect, useContext } from "react";
import { useMatch } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { WEB_ROUTES } from "../routes/web-routes";
import Header from "../components/header";
import Footer from "../components/footer";
import ProgrammeFooter from "../components/programme-footer";

export default function ProgrammesLEAP() {
  return (
    <>
      <Header />
      <main>
        <section
          className="bg-white py-60 relative bg-cover bg-top"
          style={{
            backgroundImage: "url(/images/programme-leap.png)",
          }}
        >
          <div className="absolute bottom-0 left-0 bg-black bg-opacity-50 w-full text-base leading-7 text-white md:px-0 px-8">
            <div className="container h-full mx-auto py-10 ">
              <div className="mx-auto">
                <h1 className="text-5xl font-semibold">
                  LEAP Work-Readiness Programme
                </h1>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-white py-16">
          <div className="container mx-auto md:px-0 px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
              <div>
                <div className="text-base leading-7 text-gray-700">
                  <h2 className="text-4xl  mb-4">
                    The face of many workplaces and the readiness for such is
                    continuously evolving. While you may feel like the world is
                    standing still, your plans to bring your A-game to that
                    dream job interview should still be in motion... but how do
                    you make that LEAP?
                  </h2>
                </div>
              </div>
              <div>
                <p className="mb-4">
                  Having identified the need for our beneficiaries and graduates
                  at large to receive support in their transition from tertiary
                  education to the workplace, the PPS Foundation developed LEAP.
                  This is a work-readiness programme that aims to equip
                  attendees with unmatched skills, tools and mechanisms vital
                  for their assimilation into the work environment.
                </p>
                <p className="mb-4">
                  LEAP is short for Learned, Engaged, Accelerated Professionals
                  - and provides professional development workshops on topics
                  such as personal branding, communication and presentation
                  skills, CV & interview preparation, as well as financial
                  wellness geared at young professionals.
                </p>
                <a
                  href="mailto:infoppsfoundation@pps.co.za?subject=Enquire about the LEAP Work-Readiness Programme"
                  className="bg-[#B09455] text-white  py-2 px-5 rounded-full float-start cursor-pointer font-semibold mt-4"
                >
                  Enquire about the LEAP Work-Readiness Programme
                  <i className="fi fi-rr-arrow-right text-xl float-right mt-0.5 ml-1.5"></i>
                </a>
              </div>
            </div>

            <div className="pt-20 mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
              <img
                className="h-full w-full object-cover rounded-xl"
                src="/images/programmes/header-img1.png"
              />
              <img
                className="h-full w-full object-cover rounded-xl"
                src="/images/programmes/LEAP-Wits4.jpeg"
              />
            </div>
          </div>
        </section>

        <ProgrammeFooter />
      </main>
      <Footer />
    </>
  );
}
