import { Fragment } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import MediaInsightsItem from "../components/media-insights-item";

export default function MediaWorkReadiness() {
  const insights = [
    {
      title: "PPS LEAP Work-Readiness Programme: your path to success",
      snippet:
        "More than 1.5 million individuals with post-school qualifications in South Africa are unemployed. Despite their potential, these talented individuals often lack the essential skills and readiness employers require.",
      image: "/images/insights/PC-1.png",
      date: "12 Oct<br/>2023",
      description: "<p class='mb-4'></p>",
    },
    {
      title: "Putting yourself out there and becoming outstanding",
      snippet:
        "What does it mean to be outstanding? Is it the physical sense of the phrase or is it rather related to how you position yourself in relation to where you wish to be?",
      image: "/images/articles/ravi-kumar-sKZYPerA5s0-unsplash_0.jpg",
      date: "27 Sep<br/>2022",
      description: "<p class='mb-4'></p>",
    },
    {
      title: "The power of networking",
      snippet:
        "Networking is defined as individuals or businesses that explore innovative ways to collaborate for mutual benefits. It is essentially the practice of meeting new people, staying in touch and forming new relationships.",
      image: "/images/articles/niwat6434-jj-eye.jpg",
      date: "19 Jul<br/>2022",
      description: "<p class='mb-4'></p>",
    },
  ];

  return (
    <>
      <Header />
      <main>
        <section className="bg-white py-16">
          <div className="container mx-auto md:px-0 px-8">
            <div className="text-base leading-7 text-gray-700 items-center md:flex">
              <h1 className="text-5xl font-semibold items-center flex mb-4 md:mb-0">
                <i className="fi fi-rr-skill-user text-7xl mr-4"></i> Work
                Readiness Tips
              </h1>
              <span className="ml-auto bg-[#A25C5C] text-white md:float-end float-start px-4 py-2 rounded-full cursor-pointer">
                <i className="fi fi-rr-bell-ring"></i> Subscribe to work
                readiness
              </span>
              <div className="clear-both"></div>
            </div>
          </div>
        </section>

        <section className="bg-white pb-16">
          <div className="container mx-auto md:px-0 px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
              {insights.map((article, i) => (
                <Fragment key={i}>
                  <MediaInsightsItem article={article} />
                </Fragment>
              ))}
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
