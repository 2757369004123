import Header from "../components/header";
import Footer from "../components/footer";
import FormNewsletter from "../components/form-newsletter";
import { NavLink } from "react-router-dom";
import { WEB_ROUTES } from "../routes/web-routes";

export default function Contact() {
  return (
    <>
      <Header />
      <main>
        <section className="bg-white py-16">
          <div className="container mx-auto md:px-0 px-8">
            <div className="text-base leading-7 text-gray-700">
              <h1 className="pb-5 text-5xl font-semibold">
                Get in touch with us
              </h1>

              <div className="md:flex gap-x-10">
                <article className="max-w-4xl md:mb-0 mb-8">
                  <p className="mb-4">
                    Contact us for more information on Professionals Connect,
                    our <NavLink to={WEB_ROUTES.PLATFORM}>platform</NavLink> or
                    about our{" "}
                    <NavLink to={WEB_ROUTES.PROGRAMMES}>programmes</NavLink>.
                    Please note that we never share your email with anyone else.
                    For information on how we process your personal data, please
                    review our{" "}
                    <a
                      className="hover:underline"
                      href="https://www.pps.co.za/themes/custom/refresh/assets/pdf/TERMS%20AND%20CONDITIONS%20(1).pdf"
                      target="_blank"
                    >
                      privacy&nbsp;policy
                    </a>
                  </p>
                  <div className="mb-4 flex">
                    <i className="fi fi-sr-marker text-5xl text-[#B09455] mr-4 mt-1"></i>
                    <div>
                      <span className="font-bold">Address</span>
                      <br />6 Anerley Road, Parktown, 2193
                    </div>
                  </div>
                  <div className="mb-4 flex">
                    <i className="fi fi-sr-envelope text-5xl text-[#BF785E] mr-4 mt-1"></i>
                    <div>
                      <span className="font-bold">Email</span>
                      <br />
                      infoppsfoundation@pps.co.za
                    </div>
                  </div>
                  <div className="mb-4 flex">
                    <i className="fi fi-sr-phone-call text-5xl text-[#81957E] mr-4 mt-1"></i>
                    <div>
                      <span className="font-bold">Phone Number</span>
                      <br />
                      011 644 4200
                    </div>
                  </div>
                </article>
                <form className="w-full lg:col-span-5">
                  <div className="mb-4">
                    <input
                      name="fname"
                      type="text"
                      required
                      className={`w-full flex-auto border-0 px-3.5 py-2 shadow-sm ring-0 focus:ring-0 placeholder-neutral-400 bg-zinc-200 text-zinc-500`}
                      placeholder="First name"
                    />
                  </div>
                  <div className="mb-4">
                    <input
                      name="lname"
                      type="text"
                      required
                      className={`w-full flex-auto border-0 px-3.5 py-2 shadow-sm ring-0 focus:ring-0 placeholder-neutral-400 bg-zinc-200 text-zinc-500`}
                      placeholder="Last name"
                    />
                  </div>
                  <div className="mb-4">
                    <input
                      name="subject"
                      type="text"
                      required
                      className={`w-full flex-auto border-0 px-3.5 py-2 shadow-sm ring-0 focus:ring-0 placeholder-neutral-400 bg-zinc-200 text-zinc-500`}
                      placeholder="Subject"
                    />
                  </div>

                  <div className="mb-4">
                    <textarea
                      name="message"
                      type="text"
                      required
                      className={`w-full flex-auto border-0 px-3.5 py-2 shadow-sm ring-0 focus:ring-0 placeholder-neutral-400 bg-zinc-200 text-zinc-500`}
                      placeholder="Message"
                    ></textarea>
                  </div>

                  <div className="flex gap-x-4">
                    <label htmlFor="email-address" className="sr-only">
                      Email address
                    </label>
                    <input
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className={`w-full flex-auto border-0 px-3.5 py-2 shadow-sm ring-0 focus:ring-0 placeholder-neutral-400 bg-zinc-200 text-zinc-500`}
                      placeholder="Email address"
                    />
                    <button
                      type="submit"
                      className="flex-none bg-[#091E35] rounded-md px-5 py-2 text-white inline-block"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
