import { Fragment } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import MediaArticleItem from "../components/media-article-item";

export default function MediaArticles() {
  const news = [
    {
      title: "What I wish I knew before starting my internship",
      snippet: "Accepting an internship is an exciting moment.",
      image: "/images/articles/saad-chaudhry-YNM4KStg78I-unsplash.jpg",
      date: "27 Sep<br/>2022",
      description: "<p class='mb-4'></p>",
    },
    {
      title: "Putting yourself out there and becoming outstanding",
      snippet:
        "What does it mean to be outstanding? Is it the physical sense of the phrase or is it rather related to how you position yourself in relation to where you wish to be?",
      image: "/images/articles/ravi-kumar-sKZYPerA5s0-unsplash_0.jpg",
      date: "27 Sep<br/>2022",
      description: "<p class='mb-4'></p>",
    },
    {
      title: "Integrating work with life",
      snippet:
        "Joining the workplace, I did not know what to expect. Here is some advice I wish I had but unfortunately had to learn the hard way.",
      image: "/images/articles/pickled-stardust-4SxpE-CtDUo-unsplash_0.jpg",
      date: "27 Sep<br/>2022",
      description: "<p class='mb-4'></p>",
    },
    {
      title:
        "Making your money work for you: A self-discovery journey to wealth",
      snippet:
        "Ever heard of the phrase, “Make money work for you?” If you have, then you are not alone.",
      image: "/images/articles/hans-eiskonen-wn57cSQ7VzI-unsplash.jpg",
      date: "20 Jul<br/>2022",
      description: "<p class='mb-4'></p>",
    },
    {
      title: "The power of networking",
      snippet:
        "Networking is defined as individuals or businesses that explore innovative ways to collaborate for mutual benefits. It is essentially the practice of meeting new people, staying in touch and forming new relationships.",
      image: "/images/articles/niwat6434-jj-eye.jpg",
      date: "19 Jul<br/>2022",
      description: "<p class='mb-4'></p>",
    },
    {
      title: "Money Matters - Asset allocation when investing",
      snippet:
        "Asset allocation is a key driver to helping achieve investment outcomes over time. It is the process of allocating money in your investment portfolio among various asset classes, such as equities, bonds, property and cash.",
      image: "/images/articles/iStock-1445807259.jpg",
      date: "18 Jul<br/>2022",
      description: "<p class='mb-4'></p>",
    },
  ];

  return (
    <>
      <Header />
      <main>
        <section className="bg-white py-16">
          <div className="container mx-auto md:px-0 px-8">
            <div className="text-base leading-7 text-gray-700 items-center md:flex">
              <h1 className="text-5xl font-semibold items-center flex mb-4 md:mb-0">
                <i className="fi fi-rr-browser text-7xl mr-4"></i> Articles
              </h1>
              <span className="ml-auto bg-[#82967F] text-white md:float-end float-start px-4 py-2 rounded-full cursor-pointer">
                <i className="fi fi-rr-bell-ring"></i> Subscribe to articles
              </span>
              <div className="clear-both"></div>
            </div>
          </div>
        </section>

        <section className="bg-white pb-16">
          <div className="container mx-auto md:px-0 px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
              {news.map((article, i) => (
                <Fragment key={i}>
                  <MediaArticleItem article={article} />
                </Fragment>
              ))}
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
