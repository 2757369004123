import Header from "../components/header";
import Footer from "../components/footer";
import { NavLink } from "react-router-dom";
import { WEB_ROUTES } from "../routes/web-routes";
import ProgrammeNavigation from "../components/programme-navigation";

export default function About() {
  return (
    <>
      <Header />
      <main>
        <section className="bg-white py-16">
          <div className="container mx-auto">
            <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
              <div>
                <div className="text-base leading-7 text-gray-700 md:px-0 px-8">
                  <h1 className="text-5xl font-semibold mb-5">
                    About Professionals Connect
                  </h1>

                  <div className="max-w-xl">
                    <p className="mb-4">
                      Professionals Connect is an asset of the PPS Foundation,
                      an initiative dedicated to identifying and nurturing the
                      talent of South Africa's youth. As a facilitator and
                      partner in collaborations between various stakeholders,
                      the PPS Foundation is committed to helping young South
                      Africans recognise and realise their potential.
                    </p>
                    <NavLink
                      to={WEB_ROUTES.PLATFORM}
                      className="bg-[#B09455] text-white mr-2 py-2 px-5 rounded-full float-start cursor-pointer font-semibold"
                    >
                      Professionals Connect Platform{" "}
                      <i className="fi fi-rr-arrow-right text-xl float-right mt-0.5 ml-1.5"></i>
                    </NavLink>
                  </div>
                </div>
              </div>
              <div>
                <div className="relative md:px-0 px-8">
                  <img
                    className="h-full w-full object-cover rounded-xl"
                    src="/images/about-placeholder.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container mx-auto">
            <div className="max-w-5xl">
              <ProgrammeNavigation />
            </div>
          </div>
        </section>
        <section className="relative py-16 text-[#091E35] bg-[#F4ECE5] overflow-visible md:px-0 px-8">
          <div className="container mx-auto mb-5">
            <h2 className="text-5xl">
              <span className="font-bold">Connecting professionals{` `}</span>
              <span className="font-light">for a better tomorrow.</span>
            </h2>
          </div>

          <div className="container mx-auto">
            <div className="grid md:grid-cols-3 md:gap-x-10 md:gap-y-0 grid-cols-1 gap-y-5">
              <div className="rounded-lg bg-white p-5 shadow-md">
                <div className="text-5xl w-20 h-20 flex items-center justify-center">
                  <i className="fi fi-rr-bullseye-arrow text-[#82967F]"></i>
                </div>
                <h3 className="text-[#82967F] float-start font-bold text-2xl">
                  Our Mandate
                </h3>
                <div className="clear-both"></div>
                <p className="mt-3">
                  We collaborate with professional associations, corporations,
                  academic institutions, and experienced professionals to
                  empower students, graduates, and the young workforce. Through
                  information sessions, work-readiness programs, and employment
                  opportunities, we aim to support and guide them in their
                  career development.
                </p>
              </div>
              <div className="rounded-lg bg-white p-5 shadow-md">
                <div className="text-5xl w-20 h-20 flex items-center justify-center">
                  <i className="fi fi-br-arrow-comparison text-[#70758C]"></i>
                </div>
                <h3 className="text-[#70758C] float-start font-bold text-2xl">
                  Our Way
                </h3>
                <div className="clear-both"></div>
                <p className="mt-3">
                  The graduate outreach portal aims to bridge the gap between
                  professionals from various fields and industries, facilitating
                  the transfer of skills and knowledge for both professional and
                  personal development.
                </p>
              </div>
              <div className="rounded-lg bg-white p-5 shadow-md">
                <div className="text-5xl w-20 h-20 flex items-center justify-center">
                  <i className="fi fi-rs-dashboard-monitor text-[#A76952]"></i>
                </div>
                <h3 className="text-[#A76952] float-start font-bold text-2xl">
                  Our Portal
                </h3>
                <div className="clear-both"></div>
                <p className="my-3">
                  Our portal is a user-friendly and secure interface designed
                  for students, young graduates, and entry-level professionals.
                  It provides access to relevant industry-specific information
                  and offers a platform to search for job and mentorship
                  opportunities.
                </p>
                <span className="bg-[#A76952] text-white mr-2 py-2 px-5 rounded-full float-start cursor-pointer font-semibold">
                  Register on our platform
                  <i className="fi fi-rr-arrow-right text-xl float-right mt-0.5 ml-1.5"></i>
                </span>
              </div>
            </div>
          </div>
        </section>

        <section className="relative isolate overflow-hidden">
          <img
            src="/images/foundation-page.jpg"
            alt=""
            className="absolute inset-0 -z-10 h-full w-full object-cover"
          />
          <div className="h-full w-full bg-gray-900/40 px-8 py-48">
            <div className="container mx-auto text-center max-w-2xl">
              <h2 className="text-4xl text-white mb-10">
                <span className="font-bold">
                  Committed to helping young South Africans
                </span>{" "}
                recognise and realise their potential.
              </h2>

              <NavLink
                to={WEB_ROUTES.TESTIMONIALS}
                className="bg-[#B09455] text-white py-2 px-5 rounded-full cursor-pointer font-semibold inline-block"
              >
                View testimonials{" "}
                <i className="fi fi-rr-arrow-right text-xl float-right mt-0.5 ml-1.5"></i>
              </NavLink>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
