import { useState, useEffect, useContext } from "react";
import { useMatch } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { WEB_ROUTES } from "../routes/web-routes";
import Header from "../components/header";
import Footer from "../components/footer";
import ProgrammeFooter from "../components/programme-footer";

export default function ProgrammesBursary() {
  return (
    <>
      <Header />
      <main>
        <section
          className="bg-white py-60 relative bg-cover bg-top"
          style={{ backgroundImage: "url(/images/programme-busary.png)" }}
        >
          <div className="absolute bottom-0 left-0 bg-black bg-opacity-50 w-full text-base leading-7 text-white md:px-0 px-8">
            <div className="container h-full mx-auto py-10 ">
              <div className="mx-auto">
                <h1 className="text-5xl font-semibold">
                  PPS Foundation Bursary Programme
                </h1>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-white py-16">
          <div className="container mx-auto md:px-0 px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
              <div>
                <div className="text-base leading-7 text-gray-700 ">
                  <h2 className="text-4xl  mb-4">
                    The 2024 PPS Foundation bursary applications will open in
                    {` `}
                    <span className="font-bold">October 2024</span>.
                  </h2>

                  <p>
                    The PPS Foundation is committed to improving access to
                    education and making a measurable contribution to South
                    Africa's sustainable development by growing the professional
                    pipeline. We support tertiary students who display
                    meritorious academic achievements, and our bursaries are
                    awarded from the second year up to Honours level.
                  </p>

                  <a
                    href="https://ppsfoundation.xfour.co.za/"
                    target="_blank"
                    className="bg-[#B09455] text-white  py-2 px-5 rounded-full float-start cursor-pointer font-semibold mt-4"
                  >
                    Apply for a Bursary Programme
                    <i className="fi fi-rr-arrow-right text-xl float-right mt-0.5 ml-1.5"></i>
                  </a>
                </div>
              </div>
              <div className="space-y-4">
                <div className="text-center flex items-stretch justify-center text-white space-x-4">
                  <div className="bg-[#A25C5C] p-5 rounded-xl w-1/2">
                    <span className="text-4xl font-bold">75</span>
                    <br />
                    comprehensive bursaries awarded on average annually.
                  </div>
                  <div className="bg-[#5D465D] p-5 rounded-xl w-1/2">
                    <span className="text-4xl font-bold">+500</span>
                    <br />
                    bursaries awarded since 2016
                  </div>
                </div>

                <div className="text-center flex items-stretch justify-center text-white space-x-4">
                  <div className="bg-[#BF785E] p-5 rounded-xl w-1/2">
                    <span className="text-4xl font-bold">65%</span>
                    <br />
                    the academic average that students need to achieve and
                    maintain
                  </div>
                  <div className="bg-[#70758C] p-5 rounded-xl w-1/2">
                    <span className="text-4xl font-bold">0</span>
                    <br />
                    No work back or pay back clause for beneficiaries R0 - R1
                    000 000 Household income will be considered
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <ProgrammeFooter />
      </main>
      <Footer />
    </>
  );
}
