import { NavLink } from "react-router-dom";
import { WEB_ROUTES } from "../routes/web-routes";
import Header from "../components/header";
import Footer from "../components/footer";

export default function Platform() {
  return (
    <>
      <Header />
      <main>
        <section className="bg-white py-16">
          <div className="container mx-auto md:px-0 px-8">
            <div className="text-base leading-7 text-gray-700 items-center md:flex">
              <h1 className="mb-5 text-5xl font-semibold">Our platform</h1>
              <div className="ml-auto md:float-end gap-5">
                <a
                  href="https://rms.bluevinegroup.co.za/register/Recruiter"
                  target="_blank"
                  className="bg-[#69C5D1] text-white py-3 px-5 mr-2 rounded-full md:float-end float-start md:my-0 my-1 cursor-pointer font-semibold"
                >
                  Register as Recruiter
                </a>

                <a
                  href="https://rms.bluevinegroup.co.za/register/Mentor"
                  target="_blank"
                  className="bg-[#69C5D1] text-white py-3 px-5 mr-2 rounded-full md:float-end float-start md:my-0 my-1 cursor-pointer font-semibold"
                >
                  Register as Mentor
                </a>
                <a
                  href="https://rms.bluevinegroup.co.za/register/Candidate"
                  target="_blank"
                  className="bg-[#69C5D1] text-white py-3 px-5 mr-2 rounded-full md:float-end float-start md:my-0 my-1 cursor-pointer font-semibold"
                >
                  Register as Candidate
                </a>
              </div>
              <div className="clear-both"></div>
            </div>
          </div>
        </section>

        <section id="mission-vision" className="bg-white pb-16">
          <div className="mx-auto container">
            <div className="mx-auto">
              <dl className="grid max-w-xl grid-cols-1 lg:max-w-none lg:grid-cols-2">
                <div className="relative bg-[#FFFAF7] p-10">
                  <dt>
                    <NavLink
                      to={WEB_ROUTES.PLATFORM_MENTORSHIP}
                      className="float-left"
                    >
                      <div className="font-semibold text-white text-5xl rounded-full bg-[#70758C] py-5 px-5 mr-5 cursor-pointer">
                        <div className="w-20 h-20 flex items-center justify-center">
                          <i className="fi fi-rr-referral-user"></i>
                        </div>
                      </div>
                    </NavLink>
                    <div className="clear-both visible md:hidden"></div>
                    <h2 className="text-2xl font-semibold text-gray-900">
                      <NavLink
                        className="hover:underline hover:cursor-pointer"
                        to={WEB_ROUTES.PLATFORM_MENTORSHIP}
                      >
                        Mentorship
                      </NavLink>
                    </h2>
                  </dt>
                  <dd className="mt-2 text-base text-gray-600">
                    <p className="mb-4">
                      Unlock your full potential with our expert mentorship
                      program, designed to provide personalized guidance from
                      seasoned professionals in your industry.
                    </p>
                    <NavLink
                      to={WEB_ROUTES.PLATFORM_MENTORSHIP}
                      className="bg-[#70758C] text-white mr-2 py-2 px-5 rounded-full float-end cursor-pointer font-semibold"
                    >
                      Read more
                    </NavLink>
                  </dd>
                </div>
                <div className="relative bg-white p-10">
                  <dt>
                    <NavLink
                      to={WEB_ROUTES.PLATFORM_WORK_READINESS}
                      className="float-left m-auto"
                    >
                      <div className="font-semibold text-white text-5xl rounded-full bg-[#BF785E] py-5 px-5 mr-5 cursor-pointer">
                        <div className="w-20 h-20 flex items-center justify-center">
                          <i className="fi fi-rr-skill-user"></i>
                        </div>
                      </div>
                    </NavLink>
                    <div className="clear-both visible md:hidden"></div>
                    <h2 className="text-2xl font-semibold text-gray-900">
                      <NavLink
                        className="hover:underline hover:cursor-pointer"
                        to={WEB_ROUTES.PLATFORM_WORK_READINESS}
                      >
                        Work Readiness
                      </NavLink>
                    </h2>
                  </dt>
                  <dd className="mt-2 text-base leading-7 text-gray-600">
                    <p className="mb-4">
                      Prepare for the job market with our comprehensive work
                      readiness resources. Gain essential interview preparation
                      skills, access virtual materials, and receive career
                      guidance tailored to your needs.
                    </p>
                    <NavLink
                      to={WEB_ROUTES.PLATFORM_WORK_READINESS}
                      className="bg-[#BF785E] text-white mr-2 py-2 px-5 rounded-full float-end cursor-pointer font-semibold"
                    >
                      Read more
                    </NavLink>
                  </dd>
                </div>
                <div className="relative bg-[#FFFAF7] md:bg-white p-10">
                  <dt>
                    <NavLink
                      to={WEB_ROUTES.PLATFORM_FINANCIAL}
                      className="float-left"
                    >
                      <div className="font-semibold text-white text-5xl rounded-full bg-[#81957E] py-5 px-5 mr-5 cursor-pointer">
                        <div className="w-20 h-20 flex items-center justify-center">
                          <i className="fi fi-rr-chart-histogram"></i>
                        </div>
                      </div>
                    </NavLink>
                    <div className="clear-both visible md:hidden"></div>
                    <h2 className="text-2xl font-semibold text-gray-900">
                      <NavLink
                        className="hover:underline hover:cursor-pointer"
                        to={WEB_ROUTES.PLATFORM_FINANCIAL}
                      >
                        Financial Literacy
                      </NavLink>
                    </h2>
                  </dt>
                  <dd className="mt-2 text-base leading-7 text-gray-600">
                    <p className="mb-4">
                      Empower yourself with financial literacy through our
                      targeted resources on budgeting, financial education, and
                      planning.
                    </p>
                    <NavLink
                      to={WEB_ROUTES.PLATFORM_FINANCIAL}
                      className="bg-[#81957E] text-white mr-2 py-2 px-5 rounded-full float-end cursor-pointer font-semibold"
                    >
                      Read more
                    </NavLink>
                  </dd>
                </div>
                <div className="relative bg-white md:bg-[#FFFAF7] p-10">
                  <dt>
                    <NavLink
                      to={WEB_ROUTES.PLATFORM_JOB_OPPORTUNITIES}
                      className="float-left"
                    >
                      <div className="font-semibold text-white text-5xl rounded-full bg-[#B09455] py-5 px-5 mr-5 cursor-pointer">
                        <div className="w-20 h-20 flex items-center justify-center">
                          <i className="fi fi-rr-briefcase"></i>
                        </div>
                      </div>
                    </NavLink>
                    <div className="clear-both visible md:hidden"></div>
                    <h2 className="text-2xl font-semibold text-gray-900">
                      <NavLink
                        className="hover:underline hover:cursor-pointer"
                        to={WEB_ROUTES.PLATFORM_JOB_OPPORTUNITIES}
                      >
                        Job Opportunities
                      </NavLink>
                    </h2>
                  </dt>
                  <dd className="mt-2 text-base leading-7 text-gray-600">
                    <p className="mb-4">
                      Discover your next career move with our curated job
                      opportunities. Our platform features up-to-date job
                      adverts, a streamlined application process, and advanced
                      filtered search capabilities.
                    </p>
                    <NavLink
                      to={WEB_ROUTES.PLATFORM_JOB_OPPORTUNITIES}
                      className="bg-[#B09455] text-white mr-2 py-2 px-5 rounded-full float-end cursor-pointer font-semibold"
                    >
                      Read more
                    </NavLink>
                  </dd>
                </div>
                <div className="relative bg-[#FFFAF7] p-10">
                  <dt>
                    <NavLink
                      to={WEB_ROUTES.PLATFORM_CV_BUILDER}
                      className="float-left"
                    >
                      <div className="font-semibold text-white text-5xl rounded-full bg-[#5D465D] py-5 px-5 mr-5 cursor-pointer">
                        <div className="w-20 h-20 flex items-center justify-center">
                          <i className="fi fi-rr-resume"></i>
                        </div>
                      </div>
                    </NavLink>
                    <div className="clear-both visible md:hidden"></div>
                    <h2 className="text-2xl font-semibold text-gray-900">
                      <NavLink
                        className="hover:underline hover:cursor-pointer"
                        to={WEB_ROUTES.PLATFORM_CV_BUILDER}
                      >
                        CV Builder
                      </NavLink>
                    </h2>
                  </dt>
                  <dd className="mt-2 text-base leading-7 text-gray-600">
                    <p className="mb-4">
                      Create a standout professional CV with our intuitive CV
                      builder, tailored for professionals looking to make an
                      impact. Our platform offers an automatic CV generator with
                      multiple templates, including video options, etc.
                    </p>
                    <NavLink
                      to={WEB_ROUTES.PLATFORM_CV_BUILDER}
                      className="bg-[#5D465D] text-white mr-2 py-2 px-5 rounded-full float-end cursor-pointer font-semibold"
                    >
                      Read more
                    </NavLink>
                  </dd>
                </div>
                <div className="relative bg-white p-10">
                  <dt>
                    <NavLink
                      to={WEB_ROUTES.PLATFORM_INSIGHTS}
                      className="float-left"
                    >
                      <div className="font-semibold text-white text-5xl rounded-full bg-[#A25C5C] py-5 px-5 mr-5 cursor-pointer">
                        <div className="w-20 h-20 flex items-center justify-center">
                          <i className="fi fi-rr-web-design"></i>
                        </div>
                      </div>
                    </NavLink>
                    <div className="clear-both visible md:hidden"></div>
                    <h2 className="text-2xl font-semibold text-gray-900">
                      <NavLink
                        className="hover:underline hover:cursor-pointer"
                        to={WEB_ROUTES.PLATFORM_INSIGHTS}
                      >
                        Blog Insights
                      </NavLink>
                    </h2>
                  </dt>
                  <dd className="mt-2 text-base leading-7 text-gray-600">
                    <p className="mb-4">
                      Stay informed and ahead in your career with our Blog
                      Insights. Access a wealth of blog articles and industry
                      insights that provide valuable knowledge tailored to your
                      field of study.
                    </p>
                    <NavLink
                      to={WEB_ROUTES.PLATFORM_INSIGHTS}
                      className="bg-[#A25C5C] text-white mr-2 py-2 px-5 rounded-full float-end cursor-pointer font-semibold"
                    >
                      Read more
                    </NavLink>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
