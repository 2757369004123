import { Fragment, useEffect, useState } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import axios from "axios";
import JobItem from "../components/job-item";

export default function Jobs() {
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    axios
      .get(
        "https://rms.bluevinegroup.co.za/professionalsconnect-msrv/saved-jobs/all"
      )
      .then((res) => {
        console.log(res.data);
        setJobs(res.data);
      })
      .catch((err) => {})
      .finally(() => {});
  }, []);

  const hardjobs = [
    {
      title: "Data Delivery Associate",
      snippet:
        "The Africa Assurance Innovation and Digital Data Delivery Associate (“DDM”) will play a key role in the Innovation and Digital team. The DDM will be responsible for managing the overall delivery of the digital solutions that are on offer from the Innovation and Digital Team for a portfolio of audit clients. The DDM will also be required to ensure that the audit engagement teams are supported in linking their risks and compliance requirements to the digital solution that will appropriately address this. The DDM will also be fundamental in achieving the overall strategy of the Data and Intelligence Delivery Centre of Excellence.",
      date: "30 August 2024",
      tags: "<span class='text-white bg-green-500 mx-0.5 p-2 rounded-lg'>Gauteng</span><span class='text-white bg-red-500 mx-0.5 p-2 rounded-lg'>Permanent</span>",
    },
    {
      title: "Professional Service Agent",
      snippet:
        "A professional service agent role, reporting to the Team Manager: PPS Operations. Accountable for providing a professional service to PPS members and associated parties via an inbound contact centre model. This role works strictly on a shift work basis including night-shift work.",
      date: "29 August 2024",
      tags: "<span class='text-white bg-green-500 mx-0.5 p-2 rounded-lg'>Gauteng</span><span class='text-white bg-red-500 mx-0.5 p-2 rounded-lg'>Permanent</span>",
    },
    {
      title: "Service Consultant (HPI)",
      snippet:
        "The primary responsibility is the provision of administrative activities across PPS Health Professions Indemnity business processes to service members. Ensuring service standards are maintained and improved on for the growth and retention of business.",

      date: "29 August 2024",
      tags: "<span class='text-white bg-green-500 mx-0.5 p-2 rounded-lg'>Gauteng</span><span class='text-white bg-red-500 mx-0.5 p-2 rounded-lg'>Permanent</span>",
    },
    {
      title: "SQL Database Administrator",
      snippet:
        "Deloitte is a leading global provider of audit and assurance, consulting, financial advisory, risk advisory, tax and related services. Our global network of member firms and related entities in more than 150 countries and territories (collectively, the “Deloitte organization”) serves four out of five Fortune Global 500® companies. About The Consulting Team Deloitte Consulting has always been about helping our clients and our people move forward. It’s important that we do the same for our business: celebrating how far we’ve come, and always preparing to take our next, big steps towards a global future. We’re moving and evolving on our journey to be an undisputed leader in consulting, globally and in all major markets.",
      date: "27 August 2024",
      tags: "<span class='text-white bg-green-500 mx-0.5 p-2 rounded-lg'>Gauteng</span><span class='text-white bg-red-500 mx-0.5 p-2 rounded-lg'>Permanent</span>",
    },
    {
      title: "Technical Underwriting Administrator",
      snippet:
        "The successful candidate will be responsible for all the technical administration in the Underwriting department. This role would be ideal for someone with attention to detail, great with people, can multi-task, excellent communication skills and a team player. This will include working across all areas in Servicing and Administration with the ability to process in all facets of the department and resolve queries from start to end. They will be required to make decisions within their mandate and execute a higher quality of service combined with increased quantity as per the performance contract.",

      date: "25 August 2024",
      tags: "<span class='text-white bg-green-500 mx-0.5 p-2 rounded-lg'>Gauteng</span><span class='text-white bg-red-500 mx-0.5 p-2 rounded-lg'>Permanent</span>",
    },
    {
      title: "E-Learning Practitioner",
      snippet:
        "This job description can be customized based on the specific needs and requirements of the organization hiring for the e-learning position",

      date: "03 June 2024",
      tags: "<span class='text-white bg-green-500 mx-0.5 p-2 rounded-lg'>Gauteng</span><span class='text-white bg-red-500 mx-0.5 p-2 rounded-lg'>Permanent</span>",
    },
  ];

  return (
    <>
      <Header />
      <main>
        <section className="bg-white py-16">
          <div className="container mx-auto md:px-0 px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
              <div className="lg:pr-4">
                <div className="relative">
                  <img
                    className="h-full w-full object-cover"
                    src="/images/work-for-sakhiwo.webp"
                    alt="Careers"
                  />
                </div>
              </div>
              <div>
                <div className="text-base leading-7 text-gray-700">
                  <h1 className="pb-5 text-5xl font-semibold">Find a job</h1>
                  <div className="max-w-xl">
                    <p className="pb-6">
                      Young professionals can check out a range of openings that
                      are published on the portal and apply for jobs that are
                      advertised by potential employers from various industries.
                      Gain access to opportunities that will kickstart your
                      career and avenues to help you transition to new areas
                      that will help enhance your career progress.
                    </p>
                  </div>
                  <div>
                    <a
                      href="https://rms.bluevinegroup.co.za/register/Recruiter"
                      target="_blank"
                      className="bg-[#69C5D1] text-white py-3 px-5 mr-2 my-2 rounded-full float-start cursor-pointer font-semibold"
                    >
                      Register as Recruiter
                    </a>

                    <a
                      href="https://rms.bluevinegroup.co.za/register/Candidate"
                      target="_blank"
                      className="bg-[#69C5D1] text-white py-3 px-5 mr-2 my-2 rounded-full float-start cursor-pointer font-semibold"
                    >
                      Register as Candidate
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-white pb-16">
          <div className="container mx-auto md:px-0 px-8">
            <div className="text-base leading-7 text-gray-700">
              <h1 className="pb-5 text-5xl font-semibold">
                Listed jobs ({hardjobs?.length})
              </h1>
            </div>
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
              {hardjobs?.map((job, i) => (
                <Fragment key={i}>
                  <JobItem job={job} />
                </Fragment>
              ))}
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
