import { NavLink } from "react-router-dom";
import { WEB_ROUTES } from "../routes/web-routes";

export default function MediaVideoItem({ article }) {
  return (
    <>
      <div className="flex flex-col items-start p-5 bg-[#FFFAF7] border-2 rounded-xl border-neutral-100">
        <div className="relative w-full">
          <div
            className="mb-5 text-neutral-400"
            dangerouslySetInnerHTML={{
              __html: article.video,
            }}
          />

          <div className="absolute -bottom-5 left-0 bg-[#091E35] p-2 text-xs font-semibold rounded-lg ">
            <span className="text-white">
              <span
                dangerouslySetInnerHTML={{
                  __html: article.date,
                }}
              />
            </span>
          </div>
        </div>
        <div className="max-w-xl">
          <div className="relative">
            <h3 className="mt-10 text-2xl text-gray-900 font-semibold">
              {article.title}
            </h3>
            <p className=" mt-3 mb-5">{article.snippet}</p>
          </div>
        </div>
      </div>
    </>
  );
}
