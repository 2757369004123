import { useContext } from "react";
import { NavLink } from "react-router-dom";
import { WEB_ROUTES } from "../routes/web-routes";

export default function Footer() {
  return (
    <footer className="bg-[#091E35]  px-8 md:px-0">
      <div className="mx-auto container pt-16">
        <NavLink to={WEB_ROUTES.HOME}>
          <img
            className="mb-5 h-14 -ml-6"
            src="/images/logo-footer.png"
            alt="Professionals Connect"
          />
        </NavLink>

        <div className="md:mt-16 mt-8 grid grid-cols-1 md:grid-cols-5 gap-8 md:col-span-2 xl:mt-0">
          <div>
            <h3 className="font-semibold text-white">Professionals Connect</h3>
            <ul className="mt-6 space-y-4">
              <li>
                <NavLink
                  to={WEB_ROUTES.ABOUT}
                  className="text-neutral-400 hover:text-white"
                >
                  About us
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={WEB_ROUTES.FOUNDATION}
                  className="text-neutral-400 hover:text-white"
                >
                  PPS Foundation
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={WEB_ROUTES.PLATFORM}
                  className="text-neutral-400 hover:text-white"
                >
                  Our Platform
                </NavLink>
              </li>
              <li>
                <a
                  href="https://rms.bluevinegroup.co.za/"
                  target="_blank"
                  className="text-neutral-400 hover:text-white"
                >
                  Login
                </a>
              </li>
              <li>
                <a
                  href="https://rms.bluevinegroup.co.za/register/Candidate"
                  target="_blank"
                  className="text-neutral-400 hover:text-white"
                >
                  Register
                </a>
              </li>
            </ul>
          </div>
          <div className="mt-10 md:mt-0">
            <h3 className="font-semibold text-white">Programmes</h3>
            <ul className="mt-6 space-y-4">
              <li>
                <NavLink
                  to={WEB_ROUTES.PROGRAMMES_BURSARY}
                  className="text-neutral-400 hover:text-white"
                >
                  Bursary Programme
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={WEB_ROUTES.PROGRAMMES_USP}
                  className="text-neutral-400 hover:text-white"
                >
                  University Support Programme
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={WEB_ROUTES.PROGRAMMES_GIDP}
                  className="text-neutral-400 hover:text-white"
                >
                  Graduate Internship Development Programme
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={WEB_ROUTES.PROGRAMMES_LEAP}
                  className="text-neutral-400 hover:text-white"
                >
                  Leap: Work Readiness Programme
                </NavLink>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="font-semibold text-white">Media</h3>
            <ul className="mt-6 space-y-4">
              <li>
                <NavLink
                  to={WEB_ROUTES.MEDIA_ARTICLES}
                  className="text-neutral-400 hover:text-white"
                >
                  Articles
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={WEB_ROUTES.MEDIA_VIDEOS}
                  className="text-neutral-400 hover:text-white"
                >
                  Podcasts & Videos
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={WEB_ROUTES.MEDIA_INSIGHTS}
                  className="text-neutral-400 hover:text-white"
                >
                  Insights
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={WEB_ROUTES.MEDIA_WORK_READINESS}
                  className="text-neutral-400 hover:text-white"
                >
                  Work Readiness
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={WEB_ROUTES.MEDIA_EVENTS}
                  className="text-neutral-400 hover:text-white"
                >
                  Events
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="mt-10 md:mt-0">
            <h3 className="font-semibold text-white">Get in touch</h3>
            <ul className="mt-6 space-y-4">
              <li>
                <NavLink
                  to={`#.`}
                  className="text-neutral-400 hover:text-white"
                >
                  011 644 4200
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`#.`}
                  className="text-neutral-400 hover:text-white"
                >
                  infoppsfoundation@pps.co.za
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`#.`}
                  className="text-neutral-400 hover:text-white"
                >
                  6 Anerley Road, Parktown, 2193 Stay in touch
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={WEB_ROUTES.NEWSLETTER}
                  className="text-neutral-400 hover:text-white"
                >
                  Sign up to our newsletter
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={WEB_ROUTES.CONTACT}
                  className="text-neutral-400 hover:text-white"
                >
                  Contact us
                </NavLink>
              </li>
            </ul>
          </div>

          <div className="mt-10 md:mt-0">
            <h3 className="font-semibold text-white">Follow us</h3>
            <ul className="mt-6 space-y-4">
              <li className="group">
                <a
                  href="https://www.linkedin.com/company/pps/"
                  target="_blank"
                  className="text-neutral-400 group-hover:text-[#B09455]"
                >
                  <i className="fi fi-brands-linkedin text-2xl float-left mr-2"></i>
                  <h3 className="font-semibold group-hover:text-white">
                    LinkedIn
                  </h3>

                  <div className="clear-both"></div>
                </a>
              </li>
              <li className="group">
                <a
                  href="https://www.facebook.com/PPSforProfessionals/"
                  target="_blank"
                  className="text-neutral-400 group-hover:text-[#B09455]"
                >
                  <i className="fi fi-brands-facebook text-2xl float-left mr-2"></i>
                  <h3 className="font-semibold group-hover:text-white">
                    Facebook
                  </h3>

                  <div className="clear-both"></div>
                </a>
              </li>
              <li className="group">
                <a
                  href="https://www.youtube.com/user/PPSforProfessionals/featured/"
                  target="_blank"
                  className="text-neutral-400 group-hover:text-[#B09455]"
                >
                  <i className="fi fi-brands-youtube text-2xl float-left mr-2"></i>
                  <h3 className="font-semibold group-hover:text-white">
                    Youtube
                  </h3>

                  <div className="clear-both"></div>
                </a>
              </li>
              <li className="group">
                <a
                  href="https://twitter.com/pps_sa/"
                  target="_blank"
                  className="text-neutral-400 group-hover:text-[#B09455]"
                >
                  <i className="fi fi-brands-twitter-alt-circle text-2xl float-left mr-2"></i>
                  <h3 className="font-semibold group-hover:text-white">X</h3>

                  <div className="clear-both"></div>
                </a>
              </li>
              <li className="group">
                <a
                  href="https://www.instagram.com/pps_for_professionals/"
                  target="_blank"
                  className="text-neutral-400 group-hover:text-[#B09455]"
                >
                  <i className="fi fi-brands-instagram text-2xl float-left mr-2"></i>

                  <h3 className="font-semibold group-hover:text-white ">
                    Instagram
                  </h3>

                  <div className="clear-both"></div>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="mt-8 border-t border-white/10 py-8 md:space-x-4 space-x-0 md:space-y-0 space-y-4">
          <a
            href="https://www.pps.co.za/themes/custom/refresh/assets/pdf/TERMS%20AND%20CONDITIONS%20(1).pdf"
            target="_blank"
            className=" text-neutral-400 md:float-left hover:text-white md:inline block"
          >
            Terms and Conditions
          </a>
          <div className="text-neutral-400 md:float-right hover:text-white float-left mb-0 md:mt-0 mt-4">
            &copy; PPS Foundation - {new Date().getFullYear()}. All rights
            reserved.
          </div>

          <div className="clear-both"></div>
        </div>
      </div>
    </footer>
  );
}
