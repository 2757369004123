import Header from "../components/header";
import Footer from "../components/footer";
import { NavLink } from "react-router-dom";
import { WEB_ROUTES } from "../routes/web-routes";

export default function Foundation() {
  return (
    <>
      <Header />
      <main>
        <section className="bg-white py-16">
          <div className="container mx-auto md:px-0 px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-10 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
              <div>
                <div className="text-base leading-7 text-gray-700 lg:max-w-lg">
                  <h1 className="text-5xl font-semibold mb-5">
                    About PPS Foundation
                  </h1>

                  <div className="flex items-center md:justify-center">
                    <img
                      className="pt-5 pb-3 h-max"
                      src="/images/PPS-Foundation-Logo.png"
                    />
                  </div>
                  <div className="text-center py-5 flex md:items-center items-stretch md:justify-center">
                    <NavLink
                      to={WEB_ROUTES.PROGRAMMES}
                      className="bg-gray-100 p-5 mx-2 rounded-xl w-1/2"
                    >
                      <i className="fi fi-rr-team-check-alt text-5xl"></i>
                      <br />
                      OUR PROGRAMMES
                    </NavLink>
                    <a
                      href="https://ppsfoundation.pps.co.za/"
                      target="_blank"
                      className="bg-gray-100 p-5 mx-2 rounded-xl w-1/2"
                    >
                      <i className="fi fi-rs-hands-heart text-5xl"></i>
                      <br />
                      DONATE
                    </a>
                  </div>
                </div>
              </div>
              <div>
                <div className="text-base leading-7 text-gray-700 lg:max-w-lg">
                  <div className="max-w-xl">
                    <h2 className="text-4xl mb-4">
                      <span className="font-light">Building a{` `}</span>
                      <span className="font-bold">brighter tomorrow.</span>
                    </h2>
                    <p className="font-bold mb-4">
                      Empowering the future leaders of South Africa, driving
                      community transformation!
                    </p>
                    <p className="mb-4">
                      The PPS Foundation was established in 2016 with a resolute
                      commitment to advancing various goals, including quality
                      education, gender equality, decent employment, economic
                      growth and industry innovation, as outlined in the United
                      Nation's sustainable development goals (SDGs).
                    </p>
                    <p className="mb-4">
                      Our primary objective is to enhance access to the fields
                      of science, technology, engineering and mathematics (STEM)
                      while significantly contributing to the sustainable
                      development of South African communities. Our unwavering
                      motivation is rooted in PPS's core values of mutuality and
                      collective success. Our mission remains dedicated to
                      nurturing the potential of young people and effecting
                      positive change.
                    </p>
                    <a
                      href="https://www.pps.co.za/foundation/overview/explore-pps-foundation"
                      target="_blank"
                      className="bg-[#B09455] text-white  py-2 px-5 rounded-full float-start cursor-pointer font-semibold"
                    >
                      Explore PPS Foundation
                      <i className="fi fi-rr-arrow-right text-xl float-right mt-0.5 ml-1.5"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
