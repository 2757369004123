import { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import { WEB_ROUTES } from "../routes/web-routes";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

export default function Header() {
  return (
    <>
      <header
        className="site-header is--white md:py-3 py-10 border-b"
        id="sticky-menu"
      >
        <div className="container mx-auto md:px-0 px-8">
          <div className="flex items-center justify-between gap-x-8">
            <NavLink to={WEB_ROUTES.HOME} className="inline-block w-72 h-auto">
              <img
                src="/images/ProfessionalsConnectLogo.png"
                alt="Professionals Connect Logo"
              />
            </NavLink>

            <div className="menu-block-wrapper">
              <div className="menu-overlay"></div>
              <nav className="menu-block" id="append-menu-header">
                <div className="mobile-menu-head">
                  <div className="go-back">
                    <img
                      className="dropdown-icon"
                      src="/images/icon-black-long-arrow-right.svg"
                      alt="cheveron-right"
                      width="16"
                      height="16"
                    />
                  </div>
                  <div className="current-menu-title"></div>
                  <div className="mobile-menu-close">&times;</div>
                </div>
                <ul className="site-menu-main">
                  <li className="nav-item">
                    <NavLink to={WEB_ROUTES.HOME} className="nav-link-item">
                      Home
                    </NavLink>
                  </li>
                  <li className="nav-item nav-item-has-children">
                    <NavLink
                      to={WEB_ROUTES.ABOUT}
                      className="nav-link-item drop-trigger"
                    >
                      About
                      <img
                        className="dropdown-icon"
                        src="/images/icon-black-cheveron-right.svg"
                        alt="cheveron-right"
                        width="16"
                        height="16"
                      />
                    </NavLink>
                    <ul className="sub-menu" id="submenu-1">
                      <li className="sub-menu--item">
                        <NavLink to={WEB_ROUTES.ABOUT}>About us</NavLink>
                      </li>
                      <li className="sub-menu--item">
                        <NavLink to={WEB_ROUTES.PLATFORM}>Our Platform</NavLink>
                      </li>
                      <li className="sub-menu--item">
                        <NavLink to={WEB_ROUTES.FOUNDATION}>
                          PPS Foundation
                        </NavLink>
                      </li>
                    </ul>
                  </li>

                  <li className="nav-item nav-item-has-children">
                    <NavLink
                      to={WEB_ROUTES.PROGRAMMES}
                      className="nav-link-item drop-trigger"
                    >
                      Programmes
                      <img
                        className="dropdown-icon"
                        src="/images/icon-black-cheveron-right.svg"
                        alt="cheveron-right"
                        width="16"
                        height="16"
                      />
                    </NavLink>
                    <ul className="sub-menu" id="submenu-2">
                      <li className="sub-menu--item">
                        <NavLink to={WEB_ROUTES.PROGRAMMES_BURSARY}>
                          Bursary Programme
                        </NavLink>
                      </li>
                      <li className="sub-menu--item">
                        <NavLink to={WEB_ROUTES.PROGRAMMES_USP}>
                          University Support Programme
                        </NavLink>
                      </li>
                      <li className="sub-menu--item">
                        <NavLink to={WEB_ROUTES.PROGRAMMES_GIDP}>
                          Graduate Internship Development Programme
                        </NavLink>
                      </li>
                      <li className="sub-menu--item">
                        <NavLink to={WEB_ROUTES.PROGRAMMES_LEAP}>
                          Leap: Work Readiness Programme
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item nav-item-has-children">
                    <NavLink
                      to={WEB_ROUTES.MEDIA_ARTICLES}
                      className="nav-link-item drop-trigger"
                    >
                      Media
                      <img
                        className="dropdown-icon"
                        src="/images/icon-black-cheveron-right.svg"
                        alt="cheveron-right"
                        width="16"
                        height="16"
                      />
                    </NavLink>
                    <ul className="sub-menu" id="submenu-1">
                      <li className="sub-menu--item">
                        <NavLink to={WEB_ROUTES.MEDIA_ARTICLES}>
                          Articles
                        </NavLink>
                      </li>
                      <li className="sub-menu--item">
                        <NavLink to={WEB_ROUTES.MEDIA_VIDEOS}>
                          Videos & Podcasts
                        </NavLink>
                      </li>
                      <li className="sub-menu--item">
                        <NavLink to={WEB_ROUTES.MEDIA_INSIGHTS}>
                          Insights
                        </NavLink>
                      </li>
                      <li className="sub-menu--item">
                        <NavLink to={WEB_ROUTES.MEDIA_WORK_READINESS}>
                          Work Readiness
                        </NavLink>
                      </li>
                      <li className="sub-menu--item">
                        <NavLink to={WEB_ROUTES.MEDIA_EVENTS}>Events</NavLink>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <NavLink to={WEB_ROUTES.JOBS} className="nav-link-item">
                      Jobs
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to={WEB_ROUTES.CONTACT} className="nav-link-item">
                      Contact
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <a
                      href="https://rms.bluevinegroup.co.za/"
                      target="_blank"
                      className="nav-link-item "
                    >
                      <span className="bg-[#69C5D1] text-white py-2 px-5 rounded-full cursor-pointer">
                        Login
                      </span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>

      <a
        href="https://ppsfoundation.pps.co.za/"
        target="_blank"
        class="fixed top-[50%] z-50 right-0 bg-[#B09455] text-white text-sm px-2 py-1 rounded-tl-md rounded-bl-md flex align-middle justify-center"
      >
        <i className="fi fi-rs-hands-heart text-2xl mr-2"></i>
        <span className="mt-1">Donate to the PPS Foundation</span>
      </a>
    </>
  );
}
