import { Fragment } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import MediaInsightsItem from "../components/media-insights-item";

export default function MediaInsights() {
  const insights = [
    {
      title:
        "Empowering tomorrow's Leaders: The PPS Graduate Internship Development Programme",
      snippet:
        "At PPS, we are dedicated to supporting the growth and development of young people in South Africa.",
      image: "/images/insights/PC-2.png",
      date: "12 Oct<br/>2023",
      description: "<p class='mb-4'></p>",
    },

    {
      title: "PPS Bursary Roadshow: Strengthening bonds and empowering futures",
      snippet:
        "In a world where digital communication has become the norm, face-to-face interactions have immense value. That is why the PPS Bursary Roadshow has become a cherished tradition.",
      image: "/images/insights/Structure.png",
      date: "12 Oct<br/>2023",
      description: "<p class='mb-4'></p>",
    },
    {
      title: "PPS LEAP Work-Readiness Programme: your path to success",
      snippet:
        "More than 1.5 million individuals with post-school qualifications in South Africa are unemployed. Despite their potential, these talented individuals often lack the essential skills and readiness employers require.",
      image: "/images/insights/PC-1.png",
      date: "12 Oct<br/>2023",
      description: "<p class='mb-4'></p>",
    },
    {
      title:
        "Growing and connecting the right professionals through Professionals Connect",
      snippet:
        "PPS is about more than just providing end-to-end financial services to graduate professionals.",
      image: "/images/insights/Growing.png",
      date: "13 Apr<br/>2023",
      description: "<p class='mb-4'></p>",
    },
    {
      title:
        "Nurturing the potential of our future professionals: Timeeka Govender",
      snippet:
        "The PPS Foundation improves young South Africans' access to tertiary education by selecting students displaying meritorious academic achievements and supporting them throughout their academic tenure.",
      image: "/images/insights/Timeeka-Govender.png",
      date: "13 Apr<br/>2023",
      description: "<p class='mb-4'></p>",
    },
    {
      title:
        "Fostering a community of giving through the Healthy Weigh Challenge",
      snippet:
        "The World Health Organisation makes it clear: “Health is a state of complete physical, mental and social well-being and not merely the absence of disease or infirmity”.",
      image: "/images/insights/HWC_Socials.png",
      date: "13 Apr<br/>2023",
      description: "<p class='mb-4'></p>",
    },
  ];

  return (
    <>
      <Header />
      <main>
        <section className="bg-white py-16">
          <div className="container mx-auto md:px-0 px-8">
            <div className="text-base leading-7 text-gray-700 items-center md:flex">
              <h1 className="text-5xl font-semibold items-center flex mb-4 md:mb-0">
                <i className="fi fi-rr-lightbulb-on text-7xl mr-4"></i> Insights
              </h1>
              <span className="ml-auto bg-[#5D465D] text-white md:float-end float-start px-4 py-2 rounded-full cursor-pointer">
                <i className="fi fi-rr-bell-ring"></i> Subscribe to articles
              </span>
              <div className="clear-both"></div>
            </div>
          </div>
        </section>

        <section className="bg-white pb-16">
          <div className="container mx-auto md:px-0 px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
              {insights.map((article, i) => (
                <Fragment key={i}>
                  <MediaInsightsItem article={article} />
                </Fragment>
              ))}
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
